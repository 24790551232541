import React, {useEffect, useRef, useState} from 'react'
import s from './Settings.module.css'
import {useTranslation} from "react-i18next";
import JsPageHelper from "../../../../utils/JsPageHelper";
import AjaxCryptoHelper from "../../../../ajax/AjaxCryptoHelper";
import {useDispatch, useSelector} from "react-redux";
import {setApiKey, setNotificationUrl} from "../../../../store/slices/Crypto/CommonReducer";
import {CryptoCurrency} from "../../../../constants/CryptoCurrency";
import {RefreshIconSvg} from "../../../../utils/sprite";

const CopyIconPath = require('../../../../assets/img/copy.png');

const Settings = () => {
  const {t} = useTranslation();

  const dispatch = useDispatch();

  const {apiKey, notificationUrl, addresses, wallets} = useSelector(store => store.cryptoService);

  //const callback = useRef(notificationUrl);
  const [callback, setCallback] = useState(null)

  const [lastAddresses, setLastAddresses] = useState([]);

    useEffect(async () => {
        await dispatch(await AjaxCryptoHelper.getReduxApiSettings())
    }, [])

    useEffect( () => {
        const fetchSettingsData = async () => {
            await dispatch(await AjaxCryptoHelper.getReduxDynamicAddresses())

            const addrs = addresses.slice().sort((a,b) => a.id < b.id ? 1 : -1);
            const result = [null, null, null];

            addrs.forEach(addr => {
                if (!result[2] && addr.format === 'TRON') {
                    result[2] = addr.val;
                }

                if (!result[1] && addr.format === 'HEX') {
                    result[1] = addr.val;
                }

                if (!result[0] && (addr.format !== 'HEX') && (addr.format !== 'TRON')) {
                    result[0] = addr.val;
                }
            })

            setLastAddresses(result)
        }

        fetchSettingsData().catch((err) => console.log(err));
    }, [lastAddresses]);

    const generateApiKey = async () => {
        const key = await AjaxCryptoHelper.setApiKey();
        await dispatch(setApiKey(key))
    }

    const refreshAddress = async (coin) => {
        const id = wallets.filter(w => w.coin === coin)[0].id;
        await AjaxCryptoHelper.createDynamicAddress(id);
        //await dispatch(await AjaxCryptoHelper.getReduxDynamicAddresses())
    }

    const submitCallback = async (url) => {
        await AjaxCryptoHelper.setNotificationUrl(url)
        await dispatch(setNotificationUrl({callbackUrl: url}))
    }


  return (
      <div classname={s.wrapper}>
          <form className={s.formWrapper} onSubmit={(event) => event.preventDefault()}>
              <div className={s.formItem}>
                  <label htmlFor="apiKey">{t('personal.apiSettings.generateKey')}</label>
                  <div className={s.inputWrapper}>
                      <input name="apiKey" className={s.input} type="text"
                             value={apiKey}
                             placeholder={t('personal.apiSettings.empty')}/>
                      <button
                          className={`${s.inputButton} ${s.buttonActive}`}
                          onClick={() => generateApiKey()}
                      >
                          {t('personal.apiSettings.getKey')}
                      </button>
                  </div>
              </div>
              <div className={s.formItem}>
                  <label htmlFor="notifyUrl">{t('personal.apiSettings.callbackNotify')}</label>
                  <div className={s.inputWrapper}>
                      <input name="notifyUrl" className={s.input} type="text"
                             value={callback ?? notificationUrl}
                             placeholder={t('personal.apiSettings.placeholderText')}
                             onChange={(e) => setCallback(e.target.value)}
                      />
                      <button
                          className={`${s.inputButton} ${s.buttonActive}`}
                          onClick={async (e) => await submitCallback(e.target.previousSibling.value)}
                      >
                          {t('personal.apiSettings.submit')}
                      </button>
                  </div>
              </div>

              {/*<div className={s.formItem}>
                  <label htmlFor="notifyUrl">Choose wallet to get dynamic address</label>

                  <div className={s.inputWrapper}>
                      <input name="notifyUrl" className={s.input} type="text"
                             placeholder="Wallets"/>
                      <button className={`${s.inputButton} ${s.buttonActive}`}>{t('personal.apiSettings.dynamicAddress')}</button>
                  </div>
              </div>*/}

              <div className={s.dynamicAddresses}>
                  <div className={s.dynamicAddressItem}>
                      <div className={s.addressLabel}>BITCOIN address</div>
                      <div className={s.address}>
                          <span className={s.addressValue}>
                              {lastAddresses[0] ?? '[empty]'}
                              <span>
                                  <img
                                      onClick={(e) => JsPageHelper.copyItemToBuffer(e)}
                                      className={s.copyIcon}
                                      src={CopyIconPath}
                                      alt="copy-icon"/>
                              </span>
                          </span>

                          <button
                              className={s.inputAddressButton}
                              onClick={() => refreshAddress(CryptoCurrency.BTC)}
                          >
                          <RefreshIconSvg width={15} height={15} />&nbsp;<span>Refresh</span>
                          </button>
                      </div>
                  </div>
                  <div className={s.dynamicAddressItem}>
                      <div className={s.addressLabel}>ETHEREUM address</div>
                      <div className={s.address}>
                          <span className={s.addressValue}>
                              {lastAddresses[1] ?? '[empty]'}
                              <span>
                                  <img
                                      onClick={(e) => JsPageHelper.copyItemToBuffer(e)}
                                      className={s.copyIcon}
                                      src={CopyIconPath}
                                      alt="copy-icon"/>
                              </span>
                          </span>
                          <button
                              className={s.inputAddressButton}
                              onClick={() => refreshAddress(CryptoCurrency.ETH)}
                          >
                              <RefreshIconSvg width={15} height={15}/>&nbsp;<span>Refresh</span>
                          </button>
                      </div>
                  </div>
                  <div className={s.dynamicAddressItem}>
                      <div className={s.addressLabel}>TRON address</div>
                      <div className={s.address}>
                          <span className={s.addressValue}>
                              {lastAddresses[2] ?? '[empty]'}
                              <span>
                                  <img
                                      onClick={(e) => JsPageHelper.copyItemToBuffer(e)}
                                      className={s.copyIcon}
                                      src={CopyIconPath}
                                      alt="copy-icon"/>
                              </span>
                          </span>
                          <button
                              className={s.inputAddressButton}
                              onClick={() => refreshAddress(CryptoCurrency.TRX)}
                          >
                              <RefreshIconSvg width={15} height={15}/>&nbsp;<span>Refresh</span>
                          </button>
                      </div>
                  </div>
              </div>


          </form>
      </div>
  )
}

export default Settings;

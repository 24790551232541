import React, {useLayoutEffect, useState} from 'react';
import { useNavigate} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import logo from '../../assets/img/logo.svg'
import logoMobile from '../../assets/img/logo.svg'
import MyInputForm from "../../ui/MyInputForm/MyInputForm";
import {Loader} from "../../ui/Loader/Loader";
import MyButton from "../../ui/MyButton/MyButton";
import ellipseUp from '../../assets/img/ellipseUpLogin.svg';
import ellipseDown from '../../assets/img/ellipseDownLogin.svg'
import style from "../../components/Footer/Footer.module.css";
import LanguagePicker from "../../ui/LanguagePicker/LanguagePicker";
import s from './Login.module.css'
import AjaxCryptoHelper from "../../ajax/AjaxCryptoHelper";

const LoginCrypto = () => {
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const navigate = useNavigate();

    const [data, setData] = useState({email: '', password: ''})
    const [loading, setLoading] = useState(false);

    useLayoutEffect(() => {

    }, []);


    const onSubmit = async () => {
        setLoading(true)
        const apiResponse = await AjaxCryptoHelper.getReduxUserToken(data);

        if (apiResponse.error) {
            setLoading(false)
            alert('Error: Invalid user credentials');
        }
        else {
            await dispatch(apiResponse);
            await new Promise(res => setTimeout(res, 500))
            setLoading(false)
            navigate(`/crypto/accounts`)
        }
    }

    return (
        <div className={s.loginContainer}>
            {loading ? <Loader/> :
                <>
                    <div className={s.rightSide}>
                        <img src={ellipseUp} className={s.ellipseUp} />
                        <img src={ellipseDown} className={s.ellipseDown} />
                    </div>
                    <div className={s.leftSide}>
                        <p className={style.copyright}>
                            BingoPay <br />
                            Copyright © 2023 All rights reserved</p>
                    </div>
                    <div className={s.mainContent}>
                        <div className={s.textBlock}>
                            <div className={s.textBlockTitle}>{t("signin.hello")}</div>
                            <div className={s.textBlockDesc}>{t("signin.descOne")}<br/>
                                {t("signin.descTwo")}
                            </div>
                        </div>
                        <div>
                            <div className={s.lng}>
                                <LanguagePicker/>
                            </div>
                            <div className={s.rightContent}>
                                <div className={s.header}>
                                    <div className={s.headerBlock} onClick={() => {
                                        navigate('/')
                                    }}>
                                        <div className={s.logoDesktop}><img src={logo} className={s.logo} alt=""/></div>
                                        <div className={s.logoMobile}><img src={logoMobile} alt=""/></div>
                                        <div className={s.logoTitle}>BingoPay</div>
                                    </div>
                                    <div className={s.backMobile} onClick={() => {
                                        navigate('/')
                                    }}>
                                        Back
                                    </div>
                                </div>
                                <div className={s.loginBlock}>
                                    <div className={s.loginTitle}>{t("signin.title")}</div>
                                    <div className={s.loginSubTitle}>
                                        Crypto Account
                                    </div>
                                    <div className={s.formBlock}>
                                        <div className={s.formTitle}>
                                            <span style={{color: "red"}}>* </span>
                                            {t("signin.email")}</div>
                                        <div>
                                            <MyInputForm
                                                onChange={e => setData({...data, email: e.target.value})}
                                                placeholder={t("request.email")}/></div>
                                    </div>
                                    <div className={s.formBlock}>
                                        <div className={s.formTitle}>
                                            <span style={{color: "red"}}>* </span>
                                            {t("signin.password")}</div>
                                        <div>
                                            <MyInputForm
                                                onChange={e => setData({...data, password: e.target.value})}
                                                placeholder={t("request.password")} type="password"/></div>
                                    </div>
                                    <div className={s.buttonWrapper}>
                                        <MyButton large onClick={onSubmit} className={s.button} name={t("signin.button")}/>
                                    </div>
                                </div>
                                <div className={s.reservedBlock}>
                                    <div className={s.reserved}>BingoPay</div>
                                    <div className={s.reserved}>Copyright © 2015-2023 All rights reserved</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>}
        </div>
    );
};

export default LoginCrypto;

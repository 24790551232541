import React from 'react';
import {ConfigProvider, Layout, Tabs} from 'antd';
import FiatAccounts from "../FiatAccounts/FiatAccounts";

const Accounts = () => {
  let width = window.innerWidth;
  const onChange = (key) => {
  };

  const desktopStyle = {
    backgroundColor: '#EAEAEA', marginBottom: 0, paddingTop: 16, paddingLeft: width > 1024 ? 44 : 0, display: 'none',
  }

  const items = [
    {
      key: '1',
      label: `Fiat Acquiring`,
      children: <FiatAccounts/>,
    },
  ];

  const layoutStyle = {
    backgroundColor: '#F6F4F2',

  }

  return (
      <Layout style={layoutStyle}>
        <ConfigProvider
            theme={{
              components: {
                Tabs: {
                  fontSize: 16,
                  colorBgContainer: '#F6F4F2',

                  colorFillAlter: '#EAEAEA',

                }
              },
              token: {
                colorPrimary: '#DF853B',
                colorText: '#ABABAB',
                fontFamily: 'Cera Pro',
                lineWidth: 0,
                colorPrimaryHover: '#DF853B'
              },
            }}
        >
          <Tabs tabBarStyle={desktopStyle}
                type={"card"} defaultActiveKey="1" items={items} onChange={onChange}/>
        </ConfigProvider>
      </Layout>
  );
};

export default Accounts;

import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Layout} from 'antd';
import {getAccountP2PTransactionsAndWallet} from "../../../../ajax/services/accounts";
import WithdrawalConfirmModal from '../../../../ui/modals/WithdrawalConfirmModal/WithdrawalConfirmModal';
import AccountP2PTransactions from "../AccountP2PTransactions/AccountP2PTransactions";
import {AccountCardV2} from '../../Cards/AccountCardV2/AccountCardV2';
import {Loader} from '../../../../ui/Loader/Loader';
import s from './P2PAccount.module.css';

const P2PAccount = () => {
    const dispatch = useDispatch();
    const {token} = useSelector((store) => store.authP2P);
    const {isLoading} = useSelector((store) => store.data);
    const {wallet, transactions} = useSelector((store) => store.accountP2P);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [disabled, setIsDisabled] = useState(false);
    const [status, setStatus] = useState(0);
    function withdrawalHandler(accNumber) {

    }

    useEffect(() => {
        dispatch(getAccountP2PTransactionsAndWallet(token));
    }, []);

    return (
        <Layout className={s.layout}>
            <WithdrawalConfirmModal
                status={status}
                showConfirmModal={showConfirmModal}
                showPopUp={setShowConfirmModal}
            />
            {isLoading ? (
                <Loader/>
            ) : (
                <>
                    <div className={s.accounts_list_wrapper}>
                        {wallet &&
                            wallet?.map((acc) => (
                                <AccountCardV2
                                    key={acc.id}
                                    acc={acc}
                                    crypto
                                    disabled={disabled}
                                    withdrawalHandler={withdrawalHandler}
                                />
                            ))}
                    </div>
                     <AccountP2PTransactions transactions={transactions} />
                </>
            )}
        </Layout>
    );
};
export default P2PAccount;

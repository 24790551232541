import React, {useEffect, useState} from 'react';
import s from './RequestForm.module.css'
import MyInputForm from "../../../ui/MyInputForm/MyInputForm";
import {AuthAPI} from "../../../ajax/Api";
import {Controller, useForm} from 'react-hook-form';
import PhoneCodesInput from "../../../ui/PhoneCodesInput/PhoneCodesInput";
import MyBtnOrange from "../../../ui/MyBtnOrange/MyBtnOrange";
import {useTranslation} from "react-i18next";
import CustomTab from "../../../ui/CustomTab/CustomTab";


const RequestForm = React.forwardRef((_, ref) => {
  const {t} = useTranslation()
  const formData = [t('request.getBtn'), t('request.activateBtn')];
  const [activeForm, setActiveForm] = useState(formData[0])
  const [formSuccess, setFormSuccess] = useState({isShow: false, message: '', status: ''})
  const [code, setCode] = useState({
    "co": "ad",
    "ph": "376",
    "na": "Andorra"
  })

  const [tempArr, setTempArr] = useState({
    "Name": "",
    "Company name": "",
    "Website": "",
    "E-mail": "",
    "Mobile Phone": "",
    "Message": "",
    "Country": " ",
  })


  const dataForSend = {
    project: "bingopay",
    subject: "Hello!",
    data: tempArr
  }


  const {control, handleSubmit, reset, formState: {errors}} = useForm({
    defaultValues: {
      name: '',
      companyName: '',
      website: '',
      email: '',
      mobilePhone: '',
      message: ''
    }
  });

  const onSubmit = async (data) => {

    setTempArr({
      "Name": data.name,
      "Company name": data.companyName,
      "Website": data.website,
      "E-mail": data.email,
      "Mobile Phone": `+${code.ph} ${data.mobilePhone}`,
      "Message": data.message,
      "Country": code.na,
    })


  }


  useEffect(() => {
    if (tempArr["Mobile Phone"]?.length > 0) {
      sendSupportMessage()
      reset()
    }
  }, [tempArr]);

  const sendSupportMessage = () => {
    setFormSuccess({
      isShow: false, message: '', status: ''
    })
    AuthAPI.sendSupportMessage(dataForSend)
        .then((res) => {
          setFormSuccess({
            isShow: true, message: `${t('request.successMessage')}`, status: 'ok'
          })
          setTempArr({
            "Name": "",
            "Company name": "",
            "Website": "",
            "E-mail": "",
            "Mobile Phone": "",
            "Message": "",
            "Country": " ",
          })
          reset()
        }).catch((err) => {
      setFormSuccess({
        isShow: true, message: `${t('request.errorMessage')}`, status: ''
      })
    })
  }


  return (
      <div id={'4'} className={s.container} ref={ref}>
        <div className={s.title}>{t('request.title')}</div>
        <div className={s.buttonsBlock}>
          {formData.map((item) => <CustomTab formButton key={item} onClick={() => setActiveForm(item)} name={item}
                                             active={activeForm === item}/>)}
        </div>
        {activeForm === t('request.getBtn') ?
            <form className={s.firstFormWrapper} onSubmit={handleSubmit(onSubmit)}>
              <label className={s.name}>
                <p className={s.labelTitle}><span style={{color: '#DF853B', marginLeft: 8, marginRight: 1}}>*</span>{t('request.name')}
                </p>
                <Controller
                    defaultValue=""
                    control={control}
                    rules={{
                      required: true,
                      maxLength: 150,
                    }}
                    render={({field: {onChange, value}}) => (
                        <MyInputForm
                            error={errors?.companyName}
                            placeholder={t('request.company')}
                            value={value}
                            onChange={onChange}
                        />
                    )}
                    name="name"
                />
                {errors?.companyName?.type === 'required' && <p className={s.error}>{t('request.required')}</p>}
                {errors?.companyName?.type === 'maxLength' && <p className={s.error}>{t('request.maxLengthError')}</p>}
              </label>


              <label className={s.email}>
                <p className={s.labelTitle}><span
                    style={{color: '#DF853B', marginLeft: 8, marginRight: 1}}> *</span> {t('request.email')}</p>
                <Controller
                    defaultValue=""
                    control={control}
                    rules={{
                      required: true,
                      maxLength: 40,
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                      }
                    }}
                    render={({field: {onChange, value}}) => (
                        <MyInputForm
                            error={errors?.email}
                            placeholder={t('request.companyEmail')}
                            value={value}
                            onChange={onChange}
                        />
                    )}
                    name="email"
                />
                {errors?.email?.type === 'required' && <p className={s.error}>{t('request.required')}</p>}
                {errors?.email?.type === 'pattern' && <p className={s.error}>{t('request.mailPatternError')}</p>}
              </label>

              <label className={s.mobilePhone}>
                <p className={s.labelTitle}><span
                    style={{color: '#DF853B', marginLeft: 8, marginRight: 1}}> *</span> {t('request.mobile')}</p>
                <Controller
                    defaultValue=""
                    control={control}
                    rules={{
                      required: true,
                      maxLength: 15,
                      minLength: 5,
                    }}
                    render={({field: {onChange, value}}) => (
                        <PhoneCodesInput
                            error={errors?.mobilePhone}
                            setCode={setCode}
                            value={value.replace(/[^0-9]/g, "")}
                            onChange={onChange}
                        />
                    )}
                    name="mobilePhone"
                />
                {errors?.mobilePhone?.type === 'required' && <p className={s.error}>{t('request.required')}</p>}
                {errors?.mobilePhone?.type === 'pattern' && <p className={s.error}>{t('request.onlyNumbersError')}</p>}
                {errors?.mobilePhone?.type === 'minLength' && <p className={s.error}>{t('request.minCharactersError')}</p>}
                {errors?.mobilePhone?.type === 'maxLength' && <p className={s.error}>{t('request.maxCharactersError')}</p>}
              </label>

              <label className={s.message}>
                <p className={s.labelTitle} style={{marginLeft: 16}}>{t('request.message')}</p>
                <Controller
                    defaultValue=""
                    control={control}
                    rules={{
                      maxLength: 100,
                    }}
                    render={({field: {onChange, value}}) => (
                        <textarea onChange={onChange} value={value} placeholder={t('request.yourMessage')}
                                  name='message'/>)} name="message"
                />
              </label>
              <div className={s.submitButton}>
                <MyBtnOrange name={t('request.button')}/>
                {formSuccess.isShow &&
                    <p className={formSuccess.status === 'ok' ? s.successMessage : `${s.successMessage} ${s.fail}`}>{formSuccess.message}</p>}
              </div>

            </form>

            :

            <form className={s.secondFormWrapper} onSubmit={handleSubmit(onSubmit)}>
              <label className={s.name}>
                <p className={s.labelTitle}><span style={{color: '#DF853B', marginLeft: 8, marginRight: 1}}>*</span>{t('request.name')}
                </p>
                <Controller
                    defaultValue=""
                    control={control}
                    rules={{
                      required: true,
                      maxLength: 150,
                    }}
                    render={({field: {onChange, value}}) => (
                        <MyInputForm
                            error={errors?.website}
                            placeholder={t('request.company')}
                            value={value}
                            onChange={onChange}
                        />
                    )}
                    name="name"
                />
                {errors?.website?.type === 'required' && <p className={s.error}>{t('request.required')}</p>}
                {errors?.website?.type === 'maxLength' && <p className={s.error}>{t('request.maxLengthError')}</p>}
              </label>


              <label className={s.email}>
                <p className={s.labelTitle}><span
                    style={{color: '#DF853B', marginLeft: 8, marginRight: 1}}>*</span>{t('request.email')}</p>
                <Controller
                    defaultValue=""
                    control={control}
                    rules={{
                      required: true,
                      maxLength: 40,
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                      }
                    }}
                    render={({field: {onChange, value}}) => (
                        <MyInputForm
                            error={errors?.email}
                            placeholder={t('request.companyEmail')}
                            value={value}
                            onChange={onChange}
                        />
                    )}
                    name="email"
                />
                {errors?.email?.type === 'required' && <p className={s.error}>{t('request.required')}</p>}
                {errors?.email?.type === 'pattern' && <p className={s.error}>{t('request.mailPatternError')}</p>}
              </label>

              <label className={s.mobilePhone}>
                <p className={s.labelTitle}><span style={{color: '#DF853B', marginLeft: 8, marginRight: 1}}> *</span>{t('request.mobile')}</p>
                <Controller
                    defaultValue=""
                    control={control}
                    rules={{
                      required: true,
                      maxLength: 15,
                      minLength: 5,
                    }}
                    render={({field: {onChange, value}}) => (
                        <PhoneCodesInput
                            error={errors?.mobilePhone}
                            setCode={setCode}
                            value={value.replace(/[^0-9]/g, "")}
                            onChange={onChange}
                        />
                    )}
                    name="mobilePhone"
                />
                {errors?.mobilePhone?.type === 'required' && <p className={s.error}>{t('request.required')}</p>}
                {errors?.mobilePhone?.type === 'pattern' && <p className={s.error}>{t('request.onlyNumbersError')}</p>}
                {errors?.mobilePhone?.type === 'minLength' && <p className={s.error}>{t('request.minCharactersError')}</p>}
                {errors?.mobilePhone?.type === 'maxLength' && <p className={s.error}>{t('request.maxCharactersError')}</p>}
              </label>

              <label className={s.company}>
                <p className={s.labelTitle}><span style={{color: '#DF853B', marginLeft: 8, marginRight: 1}}>*</span>{t('request.company')}</p>
                <Controller
                    defaultValue=""
                    control={control}
                    rules={{
                      required: true,
                      maxLength: 150,
                    }}
                    render={({field: {onChange, value}}) => (
                        <MyInputForm
                            error={errors?.companyName}
                            placeholder={t('request.companyName')}
                            value={value}
                            onChange={onChange}
                        />
                    )}
                    name="companyName"
                />
                {errors?.companyName?.type === 'required' && <p className={s.error}>{t('request.required')}</p>}
                {errors?.companyName?.type === 'maxLength' && <p className={s.error}>{t('request.maxLengthError')}</p>}
              </label>

              <label className={s.website}>
                <p className={s.labelTitle}><span style={{color: '#DF853B', marginLeft: 8, marginRight: 1}}>*</span>{t('request.website')}</p>
                <Controller
                    defaultValue=""
                    control={control}
                    rules={{
                      required: true,
                      maxLength: 150,
                    }}
                    render={({field: {onChange, value}}) => (
                        <MyInputForm
                            error={errors?.website}
                            placeholder={t('request.companyWebsite')}
                            value={value}
                            onChange={onChange}
                        />
                    )}
                    name="website"
                />
                {errors?.website?.type === 'required' && <p className={s.error}>{t('request.required')}</p>}
                {errors?.website?.type === 'maxLength' && <p className={s.error}>{t('request.maxLengthError')}</p>}
              </label>
              <div className={s.submitButton}>
                <MyBtnOrange name={t('request.button')}/>
                {formSuccess.isShow &&
                    <p className={formSuccess.status === 'ok' ? s.successMessage : `${s.successMessage} ${s.fail}`}>{formSuccess.message}</p>}
              </div>

            </form>}

      </div>

  );
});


export default RequestForm;

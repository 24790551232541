import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useNavigate, useParams} from "react-router";
import {useLocation} from "react-router-dom";
import {getPaymentMethodP2PForKey, getPaymentsMethodsP2P} from "../../../../ajax/services/accounts";
import {Loader} from "../../../../ui/Loader/Loader";
import {P2P} from "../P2P/P2P";
import {setPaymentApiKey, setPaymentsMethodsP2P} from "../../../../store/slices/P2PSlices/AccountsP2PReducer";

export const FormP2PHOC = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const location = useLocation()
    const apiKey = location.pathname.slice(14)

    useEffect(() => {
        if (apiKey) {
            dispatch(getPaymentMethodP2PForKey(apiKey))
            navigate(`/api/p2p`)
        }

    }, []);
    return null
}

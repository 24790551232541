import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import classNames from "classnames";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import s from "../Cards/Cards.module.css";
import arrowBack from "../../../assets/img/arrow_back.svg";

export const Wallets = () => {
    const {t} = useTranslation()
    const navigate = useNavigate();
    const data = [
        {
            key: t("wallets.table8"),
            customerCountry: t("wallets.country1"),
            paymentIntents: t("wallets.yes"),
            checkout: t("wallets.yes"),
            invoicing: t("wallets.invite"),
            connect: t("wallets.yes"),
            subscriptions: t("wallets.invite"),
            isPaymentIntents: true,
            isCheckout: true,
            isConnect: true,
            isInvoicing: false,
            isSubscriptions: false,
        },
        {
            key: t("wallets.table9"),
            customerCountry: t("wallets.globalExcept"),
            paymentIntents: t("wallets.yes"),
            checkout: t("wallets.yes"),
            invoicing: t("wallets.yes"),
            connect: t("wallets.yes"),
            subscriptions: t("wallets.yes"),
            isPaymentIntents: true,
            isCheckout: true,
            isInvoicing: true,
            isConnect: true,
            isSubscriptions: true,
        },
        {
            key: t("wallets.table10"),
            customerCountry: t("wallets.country2"),
            paymentIntents: t("wallets.yes"),
            checkout: t("wallets.yes"),
            invoicing: t("wallets.yes"),
            connect: t("wallets.yes"),
            subscriptions: t("wallets.yes"),
            isPaymentIntents: true,
            isCheckout: true,
            isInvoicing: true,
            isConnect: true,
            isSubscriptions: true,
        },
        {
            key: t("wallets.table11"),
            customerCountry: t("wallets.global"),
            paymentIntents: t("wallets.yes"),
            checkout: t("wallets.no"),
            invoicing: t("wallets.no"),
            connect: t("wallets.yes"),
            subscriptions: t("wallets.yes"),
            isPaymentIntents: true,
            isCheckout: false,
            isInvoicing: false,
            isConnect: true,
            isSubscriptions: true,
        },
        {
            key: t("wallets.table12"),
            customerCountry: t("wallets.globalExcept"),
            paymentIntents: t("wallets.yes"),
            checkout: t("wallets.yes"),
            invoicing: t("wallets.yes"),
            connect: t("wallets.yes"),
            subscriptions: t("wallets.yes"),
            isPaymentIntents: true,
            isCheckout: true,
            isInvoicing: true,
            isConnect: true,
            isSubscriptions: true,
        },
        {
            key: t("wallets.table13"),
            customerCountry: t("wallets.country3"),
            paymentIntents: t("wallets.yes"),
            checkout: t("wallets.yes"),
            invoicing: t("wallets.no"),
            connect: t("wallets.yes"),
            subscriptions: t("wallets.no"),
            isPaymentIntents: true,
            isCheckout: true,
            isInvoicing: false,
            isConnect: true,
            isSubscriptions: false,
        },
        {
            key: t("wallets.table14"),
            customerCountry: t("wallets.country4"),
            paymentIntents: t("wallets.yes"),
            checkout: t("wallets.yes"),
            invoicing: t("wallets.no"),
            connect: t("wallets.yes"),
            subscriptions: t("wallets.no"),
            isPaymentIntents: true,
            isCheckout: true,
            isInvoicing: false,
            isConnect: true,
            isSubscriptions: false,
        },
        {
            key: t("wallets.table15"),
            customerCountry: t("wallets.globalExcept2"),
            paymentIntents: t("wallets.yes"),
            checkout: t("wallets.yes"),
            invoicing: ' ',
            connect: t("wallets.yes"),
            subscriptions: ' ',
            isPaymentIntents: true,
            isCheckout: true,
            isConnect: true,
        },
        {
            key: t("wallets.table16"),
            customerCountry: t("wallets.global"),
            paymentIntents: t("wallets.yes"),
            checkout: t("wallets.yes"),
            invoicing: ' ',
            connect: t("wallets.yes"),
            subscriptions: t("wallets.yes"),
            isPaymentIntents: true,
            isCheckout: true,
            isConnect: true,
            isSubscriptions: true,
        }
    ]
    const cat = ['customerCountry', 'paymentIntents', 'checkout', 'invoicing', 'connect', 'subscriptions']
    const threshold = 100;
    const [selectedColumn, setSelectedColumn] = useState(0)
    const [pageXstart, setPageXstart] = useState(0)

    let view = cat[selectedColumn]
    const startTouch = (e) => {
        e.preventDefault()
        setPageXstart(0);
        const touch = e.changedTouches[0];
        setPageXstart(touch.pageX);
    }
    const endTouch = (e) => {
        e.preventDefault()
        let touch = e.changedTouches[0];
        let distX = touch.pageX - pageXstart;
        if (Math.abs(distX) >= threshold && distX < 0) {
            {
                selectedColumn < 5 && setSelectedColumn(prevState => prevState + 1)
            }
        }
        if (Math.abs(distX) >= threshold && distX > 0) {
            {
                selectedColumn > 0 && setSelectedColumn(prevState => prevState - 1)
            }
        }
    }
    const handleMenuNavigation = (direction) => {
        if(direction==='right' && selectedColumn < 5){
            setSelectedColumn(prevState => prevState + 1)
        }
        if(direction==='left' && selectedColumn > 0){
            setSelectedColumn(prevState => prevState -1)
        }
    }

    return (
        <div className={s.wrapper}>
            <Header/>
            <div className={s.hero}>
                <div className={s.container}>
                    <div>
                        <div>
                            <div className={s.fragmentOne}/>
                            <div className={s.fragmentTwo}/>
                            <div className={s.fragmentThree}/>
                            <div className={s.fragmentFour}/>
                        </div>
                        <button className={s.buttonBack} onClick={() => navigate('/payment-methods')}>
                            <img src={arrowBack} className={s.imgArrow} />
                            {t("paymentMethods.backBtn")}</button>
                        <div className={s.wrapMobileTitle}>
                            <h2 className={s.title}>{t("paymentMethods.wallets")}</h2>
                            <div className={s.buttonsBlock}>
                                <button  onClick={()=>handleMenuNavigation('left')} className={classNames(s.menuButton, {
                                    [s.unactive]: selectedColumn === 0,
                                })}></button>
                                <button onClick={()=>handleMenuNavigation('right')} className={classNames(s.menuButton, {
                                    [s.unactive]: selectedColumn === 3,
                                })}></button>
                            </div>
                        </div>
                        <p className={s.subtitle}>{t("wallets.title")}</p>
                        <p className={s.text}>{t("wallets.subtitle")}</p>
                    </div>
                    <div className={s.table}>
                        <div style={{transform: `translateX(${-240 * selectedColumn}px)`}} className={s.tableHeader}>
                            <div className={s.headerItemWallet}>
                                {t('wallets.table1')}
                            </div>
                            <div className={s.headerItemWallet}>
                                {t('wallets.table2')}
                            </div>
                            <div className={s.headerItemWallet}>
                                {t('wallets.table3')}
                            </div>
                            <div className={s.headerItemWallet}>
                                {t('wallets.table4')}
                            </div>
                            <div className={s.headerItemWallet}>
                                {t('wallets.table5')}
                            </div>
                            <div className={s.headerItemWallet}>
                                {t('wallets.table6')}
                            </div>
                            <div className={s.headerItemWallet}>
                                {t('wallets.table7')}
                            </div>
                        </div>
                        <div className={s.tableBodyWrapper}>
                            {
                                data.map((item, index) => {
                                    return (
                                        <div key={index} className={classNames(s.row, {[s.bottomBorderSider]: index === data.length - 1,
                                            [s.desktopHide]: index === 15
                                        })}>
                                            <div className={classNames(s.siderItem, {
                                                [s.bottomBorderSider]: index === data.length - 1,

                                            })
                                            }>{item.key}</div>
                                            <div className={classNames(s.rowItem, {
                                                [s.topLeftBorder]: index === 0,
                                                [s.bottomLeftBorder]: index === data.length - 1,
                                                [s.oneItem]: Object.keys(item).length === 2,
                                                [s.shadow]: true,
                                            })}>{item.customerCountry}
                                            </div>
                                            {item.paymentIntents && <div className={classNames(`${s.rowItem} ${s.border}`, item.isPaymentIntents ?  s.green : s.red)}>{item.paymentIntents}
                                            </div>}
                                            {item.checkout && <div className={classNames(`${s.rowItem} ${s.border}`, item.isCheckout ?  s.green : s.red )}>{item.checkout}
                                            </div>}
                                            {item.invoicing && <div className={classNames(`${s.rowItem} ${s.border}`, item.isInvoicing ? s.green : s.red)}>{item.invoicing}
                                            </div>}
                                            {item.connect && <div className={classNames(`${s.rowItem} ${s.border}`, item.isConnect ? s.green : s.red)}>{item.connect}
                                            </div>}
                                            {item.subscriptions && <div
                                                className={classNames(s.rowItem, {
                                                    [s.topRightBorder]: index === 0,
                                                    [s.bottomRightBorder]: index === data.length - 1,
                                                    [s.border]: true,
                                                }, item.isSubscriptions ? s.green : s.red)}>{item.subscriptions}
                                            </div>}
                                        </div>)
                                })
                            }
                        </div>

                        <div className={classNames(s.mobileTableBodyWrapper,
                            view === 'customerCountry' ? s.topMobileBorder : '',
                            view === 'paymentIntents' ? s.mobileBorder : '',
                            view === 'checkout' ? s.mobileBorder : '',
                            view === 'invoicing' ? s.mobileBorder : '',
                            view === 'connect' ? s.mobileBorder : '',
                            view === 'subscriptions' ? s.bottomMobileBorder : '',
                        )}
                             onTouchEnd={(e) => endTouch(e)
                             }
                             onTouchStart={(e) => startTouch(e)}
                        >
                            {
                                data.map((item, index) => {
                                    return (
                                        <div key={index} className={s.mobileTableItem}>
                                            <div className={s.mobileItemTitle}>
                                                {item.key}
                                            </div>
                                            <div className={s.mobileItemSubtitle}>
                                                {item[view] ? item[view] : item['customerCountry']}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>


                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

import React, {useEffect, useState} from 'react';
import {Link, Outlet} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import {useTranslation} from 'react-i18next';
import {Button, Layout, Menu} from 'antd';
import Sider from 'antd/es/layout/Sider';
import {Content} from 'antd/es/layout/layout';
import LoginHeader from '../../../../components/Header/LoginHeader/LoginHeader';
import {AccountsSvg, MobileMenuCloseSvg, P2PSvg} from '../../../../utils/sprite';
import {setActiveMenuP2P} from "../../../../store/slices/P2PSlices/AuthReducerP2P";
import logo from "../../../../assets/img/logo.svg";
import s from './PersonalAccountP2P.module.css';

function getItem(label, key, icon, link) {
    return {
        key,
        icon,
        label,
        link,
    };
}


const desktopSiderStyle = {
    background: '#F6F4F2',
    boxShadow: 'none',
    borderRight: '1px solid #D9D9D9',
    height: '100vh',
    zIndex: 22,
    paddingTop: 32,
};

const mobileSiderStyle = {
    background: '#3F3F3F',
    boxShadow: 'none',
    paddingTop: 60,
    zIndex: 21,
    position: 'absolute',
    top: 0,
    bottom: 0,
};

const mobileMenuActiveIcon = {
    zIndex: 25,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '16px',
    width: 'fit-content',
    height: 'fit-content',
    position: 'absolute',
    top: 16,
    left: 16,
};

const mobileMenuUnactiveIcon = {
    display: 'none',
};

const menuItemStyleMobile = {
    padding: '8px 16px',
    height: '56px',
    boxSizing: 'border-box',
    margin: 0,
    width: '100%',
};

const menuItemStyleDesktop = {
    padding: '13px 0px 13px 46px',
    height: '72px',
    boxSizing: 'border-box',
    margin: 0,
    width: '100%',
};

const mobileContentStyle = {
    padding: '23px 16px',
    marginTop: 90,
    height: '100vh',
    background: 'transparent',
    width: '100%',
};

const desktopContentStyle = {
    marginTop: 83,
    minHeight: 280,
    backgroundColor: 'F6F4F2',
    overflow: 'auto',
};

const PersonalAccountP2P = () => {
    const dispatch = useDispatch()
    const {t} = useTranslation();
    const navigate = useNavigate();
    const width = window.innerWidth;
    const [collapsed, setCollapsed] = useState(width <= 1024);
    const { token, activeMenu} = useSelector((store) => store.authP2P);
    const isAuth = useSelector((store) => store.auth.isAuth)

    const navMenuItems = [
        getItem(
            "P2P Account",
            '1',
            <P2PSvg active={activeMenu && activeMenu[0]} width={width} />,
            '/P2P/personal'
        ),
        getItem(
            t("personal.yourAccounts"),
            '2',
            <AccountsSvg active={activeMenu && activeMenu[0]} width={width}/>,
            '/P2P/accounts'
        ),
    ];

    useEffect(() => {
        if (isAuth === false || token === '') {
            navigate('/');
        }
    }, [isAuth, token]);

    const handleMenuClick = ({key}) => {
        dispatch(setActiveMenuP2P([key]))
    };

    useEffect(() => {
        return () => dispatch(setActiveMenuP2P(['1']))
    }, [])

    const navigateHandler = (link) => {
        navigate(link);
    };

    return (
        <Layout style={{width: '100vw', height: '100vh', overflow: 'auto', backgroundColor: '#F6F4F2'}}>
            <Button
                type='text'
                icon={<MobileMenuCloseSvg/>}
                onClick={() => setCollapsed(!collapsed)}
                style={
                    collapsed || width > 1024
                        ? mobileMenuUnactiveIcon
                        : mobileMenuActiveIcon
                }
            />
            <LoginHeader collapsed={collapsed} setCollapsed={setCollapsed}/>
            <Sider
                trigger={null}
                collapsible
                collapsed={collapsed}
                breakpoint={'md'}
                collapsedWidth='0'
                width={width > 1024 ? 240 : 180}
                style={width > 1024 ? desktopSiderStyle : mobileSiderStyle}
            >
                <Menu
                    style={{
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                        border: 'none',
                    }}
                    mode='inline'
                    selectedKeys={activeMenu}
                    onClick={handleMenuClick}
                    defaultSelectedKeys={activeMenu}
                >
                    <Link to={"/"} className={s.logoWrapper}>
                        <div className={s.logoBlock}>
                            <img width={31} height={36} src={logo} alt=""/>
                            <p className={s.logo}>
                                BingoPay
                            </p>
                        </div>
                    </Link>
                    {navMenuItems.map((item) => (
                        <Menu.Item
                            onClick={() => navigateHandler(item.link)}
                            style={width > 1024 ? menuItemStyleDesktop : menuItemStyleMobile}
                            className={
                                activeMenu?.includes(item.key)
                                    ? `${s.menuItem} ${s.activeMenuItem}`
                                    : `${s.menuItem}`
                            }
                            key={item.key}
                            icon={item.icon}
                        >
                            {item.label}
                        </Menu.Item>
                    ))}
                </Menu>
                <div className={collapsed ? s.mobileInfo : s.infoWrapper}>
                    <div className={s.bingo}>BingoPay</div>
                    <div className={s.copyright}>
                        <span>Copyright © 2023</span>
                        <span> All rights reserved</span>
                    </div>
                </div>
            </Sider>
            <Content style={width > 1024 ? desktopContentStyle : mobileContentStyle}>
                <Outlet/>
            </Content>
        </Layout>
    );
};

export default PersonalAccountP2P;

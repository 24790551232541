import React, {useState, useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import Icon from "@ant-design/icons";
import {ReactComponent as Copy} from "../../../../assets/img/new/copy-icon.svg";
import {ReactComponent as Warning} from "../../../../assets/img/new/warning.svg";
import MyBtnOrange from "../../../../ui/MyBtnOrange/MyBtnOrange";
import {
    approvePaymentP2PForKey,
    cancelPaymentP2PForKey,
} from "../../../../ajax/services/accounts";
import {accountP2PSlice} from "../../../../store/slices/P2PSlices/AccountsP2PReducer";
import {Loader} from "../P2P/Loader";
import s from "../P2P/P2P.module.css";

export const ReplenishmentSeparateLinkP2P = (props) => {
    const dispatch = useDispatch();
    const apiKey = useSelector((store) => store.accountP2P.apiKey.apiKey)
    const {firstNameP2P, lastNameP2P } = useSelector(store=> store.authP2P.data);
    const {successCancel} = useSelector(store => store.accountP2P.cancel);
    const {card, paymentId, timeout} = useSelector(store => store.accountP2P.data);
    const paymentAmount = +props.data.amount;
    const [isCopied, setIsCopied] = useState(false);
    const [isLoader, setIsLoader] = useState(false)

    const targetTime = timeout + 600 * 1000;
    const [time, setTime] = useState((targetTime - timeout) / 1000);
    const [timeIsOver, setTimeIsOver] = useState(false)
    const timerIDRef = useRef();

    const minutes = time > 0 ? Math.floor(time / 60) : 0;
    const seconds = time > 0 ? time - minutes * 60 : 0;
    const zeroFill = (number) => {
        let str = '0' + String(number)
        return str.slice(-2)
    }
    const clearTimer = () => {
        if (timerIDRef.current) {
            clearInterval(timerIDRef.current);
        }
    };
    useEffect(() => {
        if (time <= 0) {
            setTimeIsOver(true);
            clearTimer()
        }
    }, [time])

    const tick = () => {
        setTime(prevState => prevState - 1);
    };
    const approvePayment = (paymentId) => {
        setIsLoader(true)
        clearTimer();
        const tempObj = {
            paymentId: paymentId,
            apiKey: apiKey,
        }
        dispatch(approvePaymentP2PForKey(tempObj))
            .then((res) => {
                if (res.isSuccess === true) {
                    dispatch(accountP2PSlice.actions.resetStatus());
                    props.next()
                    props.setIsSuccess(true)
                } else {
                    dispatch(accountP2PSlice.actions.resetStatus());
                    props.next()
                    props.setIsSuccess(false)
                }
            })
            .finally(() => {
                setIsLoader(false);
            });
    }
    const cancelPayment = async (paymentId) => {
        clearTimer();
        setIsLoader(true);
        const tempObj = {
            paymentId: paymentId,
            apiKey: apiKey,
        };
        await dispatch(cancelPaymentP2PForKey(tempObj));
    }

    useEffect(() => {
        timerIDRef.current = setInterval(() => tick(), 1000);
        return clearTimer;
    }, []);

    useEffect(() => {
        if (timeIsOver) {
            cancelPayment(paymentId)
            clearTimer();
        }
    }, [timeIsOver]);

    useEffect(() => {
        if (isLoader && successCancel) {
            dispatch(accountP2PSlice.actions.resetStatus());
            props.next();
            setIsLoader(false);
            props.setIsSuccess(false)
        }
    }, [isLoader, successCancel]);

    return (
        <>
            {isLoader && <div className={s.loader}><Loader /></div>}
            <div className={s.headerWrap}>
                <div className={s.chooseBank}>
                    <div className={s.iconLoader}></div>
                    <div>
                        <p className={s.title}>Пополнение баланса на </p>
                        <span className={s.name}>карту {props.selectedCard.name}</span>
                    </div>
                </div>
                <div className={s.name}>Вы пополняете баланс аккаунта на {`${firstNameP2P} ${lastNameP2P}`}</div>
            </div>
            <div className={s.replenishmentWrap}>
                <div className={s.replenishmentImg}></div>
                <div className={s.replenishmentContent}>
                    <div className={s.countDown}>{zeroFill(minutes)} : {zeroFill(seconds)}</div>
                    <p className={s.replenishmentText}>Откройте приложение вашего банка
                        <span className={s.replenishmentText2}> и сделайте перевод </span>
                        по следующим реквизитам</p>
                    <div className={s.fieldNumberCard}>
                        <span style={{color: '#3F3F3F'}} className={s.name}>{card}</span>
                        <div className={s.copyWrap}
                             onClick={() => {
                                 setIsCopied(true);
                                 setTimeout(() => setIsCopied(false), 2000);
                                 navigator.clipboard.writeText(`${card}`);
                             }}>
                            <Icon className={isCopied ? s.copyIcon : s.inactiveIcon} component={() => <Copy/>} rev={undefined}/>
                            {isCopied ? <span className={s.copyTextActive}>Скопировано</span>  : <span className={s.copyText}>Скопировать</span>}
                        </div>
                    </div>
                    <div style={{width: '100%'}}>
                        <p className={s.replenishmentText3}>Итого к переводу:
                            <span className={s.amount} style={{fontWeight: 700}}>{paymentAmount}₽</span>
                        </p>
                    </div>
                    <div className={s.warnings}>
                        <div style={{display: 'flex'}}>
                            <Icon className={s.warningIcon} component={() => <Warning />} rev={undefined} />
                            <span className={s.replenishmentText4}>Переводить строго указанную сумму с комиссией</span>
                        </div>
                        <div style={{display: 'flex'}}>
                            <Icon className={s.warningIcon} component={() => <Warning />} rev={undefined} />
                            <span className={s.replenishmentText4}>Переводить без комментариев к платежу</span>
                        </div>
                        <div style={{display: 'flex'}}>
                            <Icon className={s.warningIcon} component={() => <Warning />} rev={undefined} />
                            <span className={s.replenishmentText4}>Переводить до истечения указанного времени</span>
                        </div>
                    </div>
                    <div className={s.wrapBtns}>
                        <MyBtnOrange name='Отмена'
                                     onClick={() => cancelPayment(paymentId, apiKey)}
                                     style={{color: "#DF853B", border: "1px solid #DF853B", background: "transparent", marginRight: "20px"}} />
                        <MyBtnOrange name='Перевести'
                                     onClick={() => approvePayment(paymentId, apiKey)} />
                    </div>
                </div>
            </div>
        </>
    )
}

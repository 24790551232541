import React, {useLayoutEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Dropdown, Space} from 'antd';
import s from "./P2P.module.css";
import MyInputForm from "../../../../ui/MyInputForm/MyInputForm";
import MyBtnOrange from "../../../../ui/MyBtnOrange/MyBtnOrange";
import './index.css'
import {createPaymentP2P, getTransactionDetails} from "../../../../ajax/services/accounts";
import {validateAmount, validateName} from "../../../../utils/validation";

export const DataInput = (props) => {
    const items = [
        {
            label: "RUB",
            key: "RUB",
        },
        // {
        //     label: "USD",
        //     key: "USD",
        // }
    ]
    const dispatch = useDispatch();
    const token = useSelector((store) => store.authP2P.token);
    const {firstNameP2P, lastNameP2P } = useSelector(store=>store.authP2P.data)
    const [currency, setСurrency] = useState('RUB');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [amountError, setAmountError] = useState('');
    const [nameError, setNameError] = useState('');

    const contentStyle = {
        width: '80px',
        fontWeight: 700,
    };

    const menuStyle = {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.08)',
        borderRadius: '16px',
        fontWeight: 700,
    };

    useLayoutEffect(() => {
        if (localStorage.getItem('currency')) {
            const currency = localStorage.getItem('currency')
            setСurrency(currency)
        }
    }, [])
    const handleItemClick = ({label, key}) => {
        localStorage.setItem('currency', key)
        setСurrency(key);
    };

    const sendDataUser = (methodId, amount, name, token) => {
        const amountValidation = validateAmount(props.data.amount);
        setAmountError(amountValidation.isValid ? '' : amountValidation.errorMessage);

        const nameValidation = validateName(props.data.name);
        setNameError(
            nameValidation.isValid ? '' : nameValidation.errorMessage
        );
        if (amountValidation.isValid && nameValidation.isValid) {
            const tempObj = {
                methodId: methodId,
                amount: amount,
                name: name,
            }
            dispatch(createPaymentP2P(tempObj, token));
            props.next()
        }
    }

    return (
        <>
            <div className={s.headerWrap}>
                <div className={s.chooseBank}>
                    <div className={s.iconLoader}></div>
                    <div>
                        <p className={s.title}>Введите необходимые данные</p>
                        <span className={s.name}>Укажите сумму платежа и ФИО плательщика</span>
                    </div>
                </div>
                <div className={s.name}>Вы пополняете баланс аккаунта на {`${firstNameP2P} ${lastNameP2P}`}</div>
            </div>
            <div className={s.form}>
                <div>
                    <div className={s.formTitle}>Сумма платежа</div>
                    <div className={s.amountWrap}>
                        <MyInputForm
                            onChange={e => props.setData({...props.data, amount: e.target.value})}
                            placeholder='Введите сумму' />
                        <Dropdown
                            placement={'bottom'}
                            onOpenChange={() => {
                                setIsDropdownOpen(!isDropdownOpen)
                            }}
                            overlayClassName={'dropdown'}
                            trigger={'click'}
                            menu={{items, onClick: handleItemClick}}
                            dropdownRender={(menu) => (
                                <div className={s.contentStyle}>
                                    {React.cloneElement(menu, {style: menuStyle})}
                                </div>
                            )}
                        >
                            <a onClick={(e) => {
                                e.preventDefault()
                            }}>
                                <Space style={{fontWeight: 700, cursor: 'pointer'}}>
                                    {currency}
                                    <img className={s.dropdownIcon}
                                         src={require('../../../../assets/img/arrow_down.png')} width={8} height={4}/>
                                </Space>
                            </a>
                        </Dropdown>
                    </div>
                    {amountError && <div className={s.error}>{amountError}</div>}
                </div>
                <div>
                    <div className={s.formTitle}>ФИО плательщика</div>
                    <div>
                        <MyInputForm
                            onChange={e => props.setData({...props.data, name: e.target.value})}
                            placeholder='Иван Иванович Иванов' />
                    </div>
                    {nameError && <div className={s.error}>{nameError}</div>}
                </div>
                <div className={s.wrapBtns}>
                    <MyBtnOrange name='Отмена'
                                 onClick={() => props.prev()}
                                 style={{color: "#DF853B", border: "1px solid #DF853B", background: "transparent", marginRight: "20px"}} />
                    <MyBtnOrange name='Продолжить'
                                 onClick={() => sendDataUser(props.methodId, props.data.amount, props.data.name, token)} />
                </div>
            </div>
        </>
    )
}

import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {ConfigProvider, Pagination, Table} from 'antd';
import i18next from 'i18next';
import classNames from "classnames";
import {getAccountP2PTransactionsAndWallet, getTransactionDetails} from '../../../../ajax/services/accounts';
import {setActiveSort} from "../../../../store/slices/P2PSlices/AccountsP2PReducer";
import s from './AccountTransactionsTableP2P.module.css';

const AccountTransactionsTableP2P = (props) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const [currentPage, setCurrentPage] = useState(1);
    const [currentData, setCurrentData] = useState([]);
    const { activeSort }=useSelector(store=>store.accountP2P)
    const {token} = useSelector((store) => store.auth);

    const tableHeader = {
        id: `${t('personal.transactionHistory.transactionNumber')}`,
        paymentId: 'PaymentID',
        createdAt: `${t('personal.transactionHistory.date')}`,
        amount: `${t('personal.transactionHistory.amount')}`,
        currency: 'Currency',
        fee: 'Fee',
        status: `${t('personal.transactionHistory.status')}`,
        description: 'Description',
        direction: 'Direction',
        bankName: 'Payment Type',
        numberTo: 'Number to',
    };

    const columns = [
        {
            title: `${i18next.t('personal.transactionHistory.transactionNumber')}`,
            dataIndex: 'id',
            className: props?.isTransactions ? '' : `${s.hide}`,
        },
        {
            title: 'Payment ID',
            dataIndex: 'paymentId',
            className: [s.paymentId]
        },
        {
            title: `${i18next.t('personal.transactionHistory.date')}`,
            dataIndex: 'createdAt',
            sorter: true,
            className: [s.dateColumn]
        },
        {
            title: `${i18next.t('personal.transactionHistory.amount')}`,
            dataIndex: 'amount',
            sorter: true,
            align:  props?.isTransactions ? 'center' : 'left',
            className: [s.amountField]
        },
        {
            title: 'Currency',
            dataIndex: 'currency',
            className: props?.isTransactions ? '' : `${s.hide}`,
        },
        {
            title: 'Fee',
            dataIndex: 'fee',
            className: props?.isTransactions ? '' : `${s.hide}`,
        },
        {
            title: `${t('personal.transactionHistory.status')}`,
            dataIndex: 'status',
            align:  props?.isTransactions ? 'left' : 'right'
        },
        {
            title: 'Description',
            dataIndex: 'description',
            className: props?.isTransactions ? '' : `${s.hide}`,
            align:  props?.isTransactions ? 'center' : 'right',
        },
        {
            title: 'Direction',
            dataIndex: 'direction',
            className: props?.isTransactions ? '' : `${s.hide}`,
            align:  props?.isTransactions ? 'center' : 'right',
        },
        {
            title: `Payment Type`,
            dataIndex: 'bankName',
            align:  props?.isTransactions ? 'left' : 'right',
            className: props?.isTransactions ? '' : `${s.hide}`,
        },
        {
            title: 'Number To',
            dataIndex: 'numberTo',
            width: props?.isTransactions ? 'initial' : '0px',
            className: props?.isTransactions ? '' : `${s.hide}`,
        },
    ];

    const onChange = (pagination, filters, sorter) => {
        const {order, field} = sorter;
        if (order === undefined) {
            dispatch(setActiveSort({}))
        } else {
            dispatch(setActiveSort({order, field}))
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        const startIndex = (currentPage - 1) * 10;
        const endIndex = startIndex + 10;
        const paginatedData = props.tableData?.slice(startIndex, endIndex);
        setCurrentData(paginatedData);
    }, [currentPage,activeSort, props.tableData]);


    const customItemRender = (current, type, originalElement) => {
        if (type === 'page' && current === currentPage) {
            return (
                <p
                    style={{
                        backgroundColor: 'transparent',
                        color: '#000',
                        fontSize: 18,
                        fontWeight: 500,
                        fontFamily: 'Cera Pro',
                    }}
                >
                    {current}
                </p>
            );
        }
        return originalElement;
    };

    const customPagination = {
        className: `${s.custom_pagination}`,
        current: currentPage,
        onChange: handlePageChange,
        itemRender: customItemRender,
        showSizeChanger:false,
        showLessItems: true
    };
    const detailsHandler = (transactionNumber) => {
        dispatch(getAccountP2PTransactionsAndWallet({transactionNumber, token}));
    };

    const returnAmount = (isTransactions, item) => {
        if (isTransactions) {
            return (<div className={classNames({
                [s.positive]: item.direction === 'IN',
                [s.negative]: item.direction === 'OUT'
            })}>{item.direction === 'OUT' ? '- ' : "+ "}{item.amount}</div>)
        } else {
            return (<div className={classNames({
                [s.positive]: item.amount > 0,
                [s.negative]: item.amount < 0
            })}>{item.amount}</div>)
        }
    }
    const returnDirection = (isTransactions, item) => {
        if (isTransactions) {
            return (<div className={classNames({
                [s.positive]: item.direction === 'IN',
                [s.negative]: item.direction === 'OUT'
            })}>{item.direction}</div>)
        }
    }


    return (
        <div>
            <img
                className={s.blankHeader}
                src={require('../../../../assets/img/blankHeader.png')}
                alt=''
            />

            <div className={s.transactionHistoryDesktop}>
                <ConfigProvider
                    theme={{
                        token: {
                            colorPrimary: '#DF853B',
                        },
                    }}
                >
                    <Table
                        rowClassName={s.tableRow}
                        style={{backgroundColor: 'transparent'}}
                        columns={columns}
                        dataSource={props.tableData?.map((item) => {
                            return {
                                key: item.id,
                                id: item.id,
                                paymentId: item.paymentId,
                                createdAt: item.createdAt.split('').slice(0, 10).join(''),
                                amount: returnAmount(props.isTransactions, item),
                                currency: item.currency,
                                fee: item.fee,
                                status: item.status,
                                description: item.description,
                                direction: returnDirection( props.isTransactions,item),
                                bankName: item.bankName,
                                numberTo: item.numberTo
                            };
                        })}
                        onChange={onChange}
                        pagination={customPagination}
                    />
                </ConfigProvider>
            </div>

            <div className={s.transactionHistoryMobile}>
                <div className={s.transactionsList}>
                    {currentData?.map((transaction) => {
                        return (
                            <div
                                className={s.transactionBlock}
                                key={transaction.id}
                            >
                                <div className={s.transactionRow}>
                                    <div className={s.rowBlock}>
                                        <div className={s.valueName}>
                                            {tableHeader.paymentId}
                                        </div>
                                        <div className={s.valuePayload}>
                                            {transaction.paymentId}
                                        </div>
                                    </div>
                                </div>

                                {props.isTransactions &&  <div className={s.transactionRow}>
                                    <div className={s.rowBlock}>
                                        <div className={s.valueName}>{tableHeader.createdAt}</div>
                                        <div className={s.valuePayload}>
                                            {transaction.createdAt.split('').slice(0, 10).join('')}
                                        </div>
                                    </div>
                                </div> }
                                {props.isTransactions &&<div className={s.transactionRow}>
                                    <div className={s.rowBlock}>
                                        <div className={s.valueName}>{tableHeader.amount}</div>
                                        <div className={s.valuePayload}>
                                            {transaction.amount} {transaction.currency}
                                        </div>
                                    </div>
                                </div>}
                                {props.isTransactions &&<div className={s.transactionRow}>
                                    <div className={s.rowBlock}>
                                        <div className={s.valueName}>{tableHeader.fee}</div>
                                        <div className={s.valuePayload}>
                                            {transaction.fee}
                                        </div>
                                    </div>
                                </div>}
                                {props.isTransactions &&<div className={s.transactionRow}>
                                    <div className={s.rowBlock}>
                                        <div className={s.valueName}>{tableHeader.status}</div>
                                        <div className={s.valuePayload}>
                                            {transaction.status}
                                        </div>
                                    </div>
                                </div>}
                                {props.isTransactions &&  <div className={s.transactionRow}>
                                    <div className={s.rowBlock}>
                                        <div className={s.valueName}>{tableHeader.bankName}</div>
                                        <div className={s.valuePayload}>
                                            {transaction.bankName}
                                        </div>
                                    </div>
                                </div>}
                                {props.isTransactions &&  <div className={s.transactionRow}>
                                    <div className={s.rowBlock}>
                                        <div className={s.valueName}>{tableHeader.numberTo}</div>
                                        <div className={s.valuePayload}>
                                            {transaction.numberTo}
                                        </div>
                                    </div>
                                </div>}
                            </div>
                        );
                    })}
                </div>
                <Pagination
                    pageSize={10}
                    total={props.tableData?.length}
                    {...customPagination}
                />
            </div>
        </div>
    );
};

export default AccountTransactionsTableP2P;

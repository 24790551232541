import React, {useRef, useState} from "react";
import {useSelector} from "react-redux";
import {NavLink} from "react-router-dom";
import {useTranslation} from 'react-i18next';
import MyButton from "../../../ui/MyButton/MyButton";
import CurrencyCard from "./components/CurrencyCard/CurrencyCard";
import RequestForm from "../RequestForm/RequestForm";
import MyBtnOrange from "../../../ui/MyBtnOrange/MyBtnOrange";
import heroImage from './../../../assets/img/new/hero.svg'
import check from './../../../assets/img/new/tick.svg'
import person from "../../../assets/img/personIcon.png";
import style from "../../../components/Header/Header.module.css";
import s from "./Main.module.css"

const Main = () => {
  const {t} = useTranslation();
  const requestFormRef = useRef();
  const scrollToRequestForm = () => {
    if (requestFormRef.current) {
      requestFormRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };


  const benefitsData = [
    {key: '01', text: t("main.benefitsText1")},
    {key: '02', text: t("main.benefitsText2")},
    {key: '03', text: t("main.benefitsText3")},
    {key: '04', text: t("main.benefitsText5")},
  ]

  const forWhomData = [
    t("main.whoText1"),
    t("main.whoText2"),
    t("main.whoText3"),
    t("main.whoText5"),
    t("main.whoText6"),
  ]

  const currenciesData = [
    {key: '011', currency: 'BTC', currencyName: 'Bitcoin'},
    {key: '012', currency: 'USDT', currencyName: 'Tether'},
    {key: '013', currency: 'ETH', currencyName: 'Ethereum'},
    {key: '014', currency: 'JPY', currencyName: 'Yen'},
    {key: '015', currency: 'KPW', currencyName: 'Won'},
    {key: '016', currency: 'TRX', currencyName: 'Tron'},
    {key: '017', currency: 'USD', currencyName: 'Dollar'},
    {key: '018', currency: 'GBP', currencyName: 'Pound Sterling'},
    {key: '019', currency: 'INR', currencyName: 'Rupee'},
    {
      key: '020', currency: 'EUR', currencyName: 'Euro'
    },
    {
      key: '021', currency: 'BUSD', currencyName: 'Binance USD'
    },
    {
      key: '022', currency: 'CNY', currencyName: 'Yuan'
    },

  ]

  return (
      <main className={s.mainWrapper} onScroll={(e)=>console.log(e)}>
        <section id={"1"} className={s.hero}>
          <div className={s.container}>
            <div className={s.heroContentWrapper}>
              <div className={s.heroInfoWrapper}>
                <h1 className={s.heroTitle}>BingoPay</h1>
                <p className={s.subtitle}>{t("main.title")}</p>
                <p className={s.description}>{t("main.subtitle1")}<br/> {t("main.subtitle2")}</p>
                <MyButton onClick={scrollToRequestForm} name={t("main.button")} heroButton/>
              </div>
              <div className={s.heroImageBlock}>
                <div className={s.fragmentOne}/>
                <div className={s.fragmentTwo}/>
                <div className={s.fragmentThree}/>
                <div className={s.fragmentFour}/>
                <img src={heroImage} alt="hero_image" style={{width: '100%'}}/>
              </div>
            </div>
          </div>
        </section>
        <section id={"2"} className={s.why}>
          <div className={s.container}>
            <div className={s.whyContentWrapper}>
              <h2 className={s.whyTitle}>{t('why.title')}</h2>
              <h2 className={s.sectionTitle}>{t('why.subtitle')}</h2>
              <div className={s.infoWrapper}>
                <div className={s.infoWrapperColumn}>
                  <div>
                    <p className={s.infoTitle}>{t('why.cardTitleOne')}</p>
                    <p className={s.infoSubtitle}>{t('why.cardOneSubtitle')}</p>
                  </div>
                  <div className={s.infoDescriptionWrapper}>
                    <div className={s.lineWrapper}>
                      <div className={s.line}/>
                      <p>{t('why.cardOneTextOne')}</p></div>
                    <div className={s.lineWrapper}>
                      <div className={s.line}/>
                      <p>{t('why.cardOneTextTwo')}</p></div>
                    <div className={s.lineWrapper}>
                      <div className={s.line}/>
                      <p>{t('why.cardOneTextThree')}</p></div>
                  </div>
                </div>
                <div className={s.infoWrapperColumn}>
                  <div>
                    <p className={s.infoTitle}>{t('why.cardTitleTwo')}</p>
                    <p className={s.infoSubtitle}>{t('why.cardTwoSubtitle')}</p>
                  </div>
                  <div className={s.infoDescriptionWrapper}>
                    <div className={s.lineWrapper}>
                      <div className={s.line}/>
                      <p>{t('why.cardTwoTextOne')}</p></div>
                    <div className={s.lineWrapper}>
                      <div className={s.line}/>
                      <p>{t('why.cardTwoTextTwo')}</p></div>
                    <div className={s.lineWrapper}>
                      <div className={s.line}/>
                      <p>{t('why.cardTwoTextThree')}</p></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id={"3"} className={s.benefits}>
          <div className={s.container}>
            <div className={s.benefitsContentWrapper}>
              <h2 className={s.sectionTitle}>{t("main.benefits")}</h2>
              <div className={s.blocksWrapper}>
                {benefitsData.map(item => (
                    <div key={item.key} className={s.cardItem}>
                      <p className={s.number}>{item.key}</p>
                      <p className={s.textBlock}>{item.text}</p>
                    </div>
                ))}

              </div>
            </div>
          </div>
        </section>

        <section id={"4"} className={s.forWhom}>
          <div className={s.container}>
            <div className={s.forWhomContentWrapper}>
              <h2 className={s.forWhomTitle}>{t("main.who")}</h2>
              <div className={s.forWhomInfoWrapper}>
                {
                  forWhomData.map(item => (
                      <div className={s.forWhomLine} key={item}>
                        <img src={check} className={s.check} alt="check_icon"/><p className={s.forWhomText}>{item}</p>
                      </div>
                  ))
                }
              </div>
            </div>
          </div>
        </section>

        <section id={"5"} className={s.currencies}>
          <div className={s.container}>
            <div className={s.currenciesContentWrapper}>
              <h2 className={s.sectionTitle}>{t("main.currencies")}</h2>
              <div className={s.currenciesBlocksWrapper}>
                {currenciesData.map(item => (
                    <CurrencyCard key={item.key} {...item}/>
                ))}
              </div>
            </div>
          </div>
        </section>
        <section id={"6"} className={s.requestForm}>
          <RequestForm ref={requestFormRef} />
        </section>
      </main>
  );
};

export default Main;

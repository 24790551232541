import React from 'react';
import {useTranslation} from "react-i18next";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import MyCollapseCrypto from "../../ui/CollapseCrypto/MyCollapseCrypto";
import s from "../PrivacyPolicy/PrivacyPolicy.module.css";

export const ApiCrypto = () => {
    const {t} = useTranslation()

    return (
        <div className={s.wrapper}>
                <Header/>
                <div className={s.hero}>
                    <div className={s.container}>
                        <div className={s.content}>
                            <div style={{marginTop: "70px"}}>
                                <div className={s.fragmentOne}/>
                                <div className={s.fragmentTwo}/>
                                <div className={s.fragmentThree}/>
                                <div className={s.fragmentFour}/>
                            </div>
                            <h2 className={s.title}>{t("apiCrypto.title")}</h2>
                        </div>
                        <MyCollapseCrypto />
                    </div>
                </div>
                <Footer/>
            </div>
    )
}
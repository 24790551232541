import React, {useEffect} from 'react'
import {Layout} from 'antd';
import s from "./AccountCard.module.css";
import {useTranslation} from "react-i18next";
import MyCardButton from "../../../../ui/MyCardBtn/MyCardButton";
import cn from "classnames";
import {useDispatch, useSelector} from "react-redux";
import CryptoDepositModal from "../../../../ui/modals/CryptoDepositModal/CryptoDepositModal";
import CryptoWithdrawalModal from "../../../../ui/modals/CryptoWithdrawalModal/CryptoWithdrawalModal";
import {setActiveAccountFilter, setActiveCurrencyFilter} from "../../../../store/slices/AcquiringSlices/AccountsReducer";

export const AccountCardV2 = ({
                                acc,
                                isTest,
                                disabled,
                                withdrawalHandler,
                                crypto,
                                showDepositModal,
                                showWithdrawalModal,
                                setShowDepositModal,
                                setShowWithdrawalModal
                              }) => {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const copyHandler = () => {
    navigator.clipboard.writeText(acc.accountNumber);
  };

  const {activeCurrencyFilter} = useSelector(state => state.accounts)

  useEffect(() => {
  }, [activeCurrencyFilter])

  const getFullCurrency = (currency) => {
    switch (currency) {
      case 'EUR':
        return 'Euro'
      case 'USD':
        return 'U.S. Dollar'
      case 'GBR':
        return 'British pound'
      case 'RUB':
        return 'Russian ruble'
      default:
        return 'Tether';
    }
  }

  const withdrawHandler = (acc, e) => {
    e.stopPropagation()

    if (crypto) {
      return setShowWithdrawalModal(true)
    }
    if (acc?.balance > 0 && crypto === false) {
      return withdrawalHandler(acc.accountNumber)
    }

  }

  const filterByCurrency = (acc) => {
    if (activeCurrencyFilter !== acc.currency) {
      dispatch(setActiveCurrencyFilter(acc.currency))
      dispatch(setActiveAccountFilter(acc.accountNumber))
    } else {
      dispatch(setActiveCurrencyFilter(''))
      dispatch(setActiveAccountFilter(''))
    }
  }


  return (
      <Layout>
      <div onClick={() => filterByCurrency(acc?.currency)} className={cn(s.cardWrapper, {
        [s.test]: isTest,
        [s.active]: acc?.currency === activeCurrencyFilter
      })}>

          <CryptoDepositModal
              acc={acc}
              crypto
              getFullCurrency={getFullCurrency}
              showDepositModal={showDepositModal}
              showPopUp={setShowDepositModal}
          />
          <CryptoWithdrawalModal
              acc={acc}
              crypto
              getFullCurrency={getFullCurrency}
              showWithdrawalModal={showWithdrawalModal}
              showPopUp={setShowWithdrawalModal}
          />

        <div className={s.infoWrapper}>
          <div className={s.infoBlock}>
            <img className={s.coinIcon}
                 src={require(`./../../../../assets/img/currency/${acc?.currency ? acc?.currency : 'USDT'}.svg`)}/>
            <div>
              <p className={s.currency}>{acc?.currency ? acc.currency : 'USDT'}</p>
              <p className={s.fullCurrency}>({getFullCurrency(acc?.currency)})</p>
            </div>
            {crypto && <span className={s.plate}>TRC-20</span>}
          </div>
          <div className={s.amountWrapper}>
            <p className={s.amount}>Amount</p>
            <div className={s.amountBlock}>
              <p className={s.amountValue}>{!!acc?.balance ? acc.balance : '0'}</p>
              <p className={s.amountCoin}>{acc?.currency ? acc.currency : 'USDT'}</p>
            </div>
          </div>
        </div>
        <div className={s.buttonsWrapper}
        >
          {crypto && <MyCardButton onClick={() => setShowDepositModal(true)} icon={'deposit'}/>}
          <MyCardButton inactive={acc?.balance <= 0 || !acc?.balance  } disabled={acc?.balance <=0} onClick={(e) => withdrawHandler(acc, e)} icon={'export'} type={'export'}/></div>
      </div>
    </Layout>
  )
}

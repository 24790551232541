import React from 'react';
import s from "./CurrencyCard.module.css";
import cn from "classnames";


export default function CurrencyCard(item) {
  return (
      <div className={cn(s.currencyItem, {
        [s.usdt]: item.currency==='USDT',
        [s.eth]: item.currency==='ETH',
        [s.jpy]: item.currency==='JPY',
        [s.kpw]: item.currency==='KPW',
        [s.trx]: item.currency==='TRX',
        [s.usd]: item.currency==='USD',
        [s.gpb]: item.currency==='GBP',
        [s.inr]: item.currency==='INR',
        [s.eur]: item.currency==='EUR',
        [s.busd]: item.currency==='BUSD',
        [s.cny]: item.currency==='CNY',
      })}>
        <img className={s.currencyIcon}
             src={require(`./../../../../../assets/img/currency/${item.currency}.svg`)}/>
        <div>
          <p className={s.currencyName}>{item.currencyName}</p>
          <p className={s.currency}>{item.currency}</p>
        </div>
      </div>
  )
}

import Icon from "@ant-design/icons";
import {ReactComponent as Cancel} from "../../../../assets/img/new/cancel.svg";
import {ReactComponent as Done} from "../../../../assets/img/new/done.svg";
import {ReactComponent as Cancellation} from "../../../../assets/img/new/payment-cancellation.svg";
import {ReactComponent as Success} from "../../../../assets/img/new/successful-payment.svg";

import s from "./P2P.module.css";
import React from "react";

export const Response = (props) => {
    const response = props.isSuccess
    return (
        <>
            <div className={s.headerWrap}>
                <div className={s.chooseBank}>
                    <Icon className={s.warningIcon} component={() => response ? <Done /> : <Cancel /> } rev={undefined} />
                    <div>
                        {response ?
                            <p className={s.title}>Оплата прошла успешно</p>
                            :
                            <>
                                <p className={s.title}>Произошла отмена платежа</p>
                            </>}
                        {response ?
                            <span className={s.name}>Платёж подтверждён</span>
                            :
                            <span className={s.name}>Платёж отменён</span> }
                    </div>
                </div>
            </div>
            <div style={{position: 'relative'}}>
                <div>
                    <Icon className={s.responseLogo} component={() => response ? <Success /> : <Cancellation /> } rev={undefined} />
                </div>
                {response ?
                    <div className={s.responseText}>
                        <p className={s.title}>Успешная оплата</p>
                        <span className={s.name}>Спасибо, что воспользовались нашим сервисом!</span>
                    </div>
                        :
                    <div className={s.responseText2}>
                        <p className={s.title}>Отмена платежа</p>
                    </div>}
            </div>
        </>
    )
}

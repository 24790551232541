import React, {useEffect} from "react";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import style from "../../../components/Nav/Nav.module.css";
import s from "./DropDown.module.css"

export const DropDown = (props) => {
    const {t} = useTranslation()
    const handleClickOutside = (event) => {
        if (!event.target.closest(`.${s.DropDownWrapper}`)) {
            props.setShowDropdown(false);
        }
    };

    useEffect(() => {
        window.addEventListener('click', handleClickOutside);
        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <div className={s.DropDownWrapper}>
            <div onClick={() => props.setShowDropdown(!props.showDropdown)}
                className={s.Link}
            >{t("header.payment-methods")}
                <img style={{marginLeft: "7px"}}
                     src={require('../../../assets/img/arrow_down.png')} width={8} height={4}/>
            </div>
            <div className={s.MobileLink}>
                <NavLink
                    className={({isActive}) =>
                        isActive ? `${style.active}` : `${style.link}`
                    }
                    to={"/payment-methods"}
                >{t("header.payment-methods")}
                </NavLink>
            </div>
            <div className={props.showDropdown ? s.DropDownList : s.NoneDropDown}>
                    <NavLink
                        className={({isActive}) =>
                            isActive ? `${s.activeItem}` : `${s.DropDownListItem}`
                        }
                             to={"/payment-methods"}
                    >{t("header.payment-methods")}
                    </NavLink>
                    <NavLink
                        className={({isActive}) =>
                            isActive ? `${s.activeItem}` : `${s.DropDownListItem}`
                        }
                        to={"/payment-methods/cards"}
                    >{t("paymentMethods.cards")}
                    </NavLink>
                    <NavLink
                        className={({isActive}) =>
                            isActive ? `${s.activeItem}` : `${s.DropDownListItem}`
                        }
                        to={"/payment-methods/wallets"}
                    >{t("paymentMethods.wallets")}
                    </NavLink>
                    <NavLink
                        className={({isActive}) =>
                            isActive ? `${s.activeItem}` : `${s.DropDownListItem}`
                        }
                        to={"/payment-methods/bank-transfers"}
                    >{t("paymentMethods.bankTransfer")}
                    </NavLink>
            </div>
        </div>
    )
}

import React from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import MyBtnOrange from "../../ui/MyBtnOrange/MyBtnOrange";
import arrowBack from "../../assets/img/arrow_back.svg";
import s from "../PaymentMethods/PaymentMethods.module.css";
import bgOne from "../../assets/img/privacyPolicy_left.svg";
import bgTwo from "../../assets/img/privacyPolicy_left_two.svg";
import bgThree from "../../assets/img/privacyPolicy_right_two.svg";
import heroImage from "../../assets/img/new/hero.svg";

export const PaymentMethods = () => {
    const {t} = useTranslation()
    const navigate = useNavigate();

    const methods = [
        {
            name: "cards",
            title: t("paymentMethods.cards"),
            description: t("paymentMethods.cardsTitle"),
            btn: t("paymentMethods.cardsBtn"),
            global: t("paymentMethods.global"),
            globalText1: t("paymentMethods.globalCardsText1"),
            globalText2: t("paymentMethods.globalCardsText2"),
            globalText3: t("paymentMethods.globalCardsText3"),
            globalText4: t("paymentMethods.globalCardsText4"),
            USCanada: t("paymentMethods.USCanada"),
            USCanadaText1: t("paymentMethods.USCanadaCardsText1"),
            USCanadaText2: t("paymentMethods.USCanadaCardsText2"),
            europe: t("paymentMethods.europe"),
            europeText1: t("paymentMethods.europeCardsText1"),
            asia: t("paymentMethods.asia"),
            asiaText1: t("paymentMethods.asiaCardsText1"),
            asiaText2: t("paymentMethods.asiaCardsText2"),
        },
        {
            name: "wallets",
            title: t("paymentMethods.wallets"),
            description: t("paymentMethods.walletsTitle"),
            btn: t("paymentMethods.walletsBtn"),
            global: t("paymentMethods.global"),
            globalText1: t("paymentMethods.globalWalletsText1"),
            globalText2: t("paymentMethods.globalWalletsText2"),
            globalText3: t("paymentMethods.globalWalletsText3"),
            globalText4: t("paymentMethods.globalWalletsText4"),
            USCanada: t("paymentMethods.USCanada"),
            USCanadaText1: t("paymentMethods.USCanadaWalletsText1"),
            europe: t("paymentMethods.europe"),
            europeText1: t("paymentMethods.europeWalletsText1"),
            europeText2: t("paymentMethods.europeWalletsText2"),
            europeText3: t("paymentMethods.europeWalletsText3"),
            asia: t("paymentMethods.asia"),
            asiaText1: t("paymentMethods.asiaWalletsText1"),
            asiaText2: t("paymentMethods.asiaWalletsText2"),
            asiaText3: t("paymentMethods.asiaWalletsText3"),
        },
        {
            name: "bank-transfers",
            title: t("paymentMethods.bankTransfer"),
            description: t("paymentMethods.bankTransferTitle"),
            btn: t("paymentMethods.bankBtn"),
            USCanada: t("paymentMethods.USCanada"),
            USCanadaText1: t("paymentMethods.USCanadaBankText1"),
            europe: t("paymentMethods.europe"),
            europeText1: t("paymentMethods.europeBankText1"),
            europeText2: t("paymentMethods.europeBankText2"),
        }
    ]

    return (
        <>
            <div className={s.wrapper}>
                <Header/>
                <div className={s.hero}>
                <div className={s.container}>
                    <div className={s.content}>
                        <div>
                            <div className={s.fragmentOne}/>
                            <div className={s.fragmentTwo}/>
                            <div className={s.fragmentThree}/>
                            <div className={s.fragmentFour}/>
                        </div>
                        <button className={s.buttonBack} onClick={() => navigate('/')}>
                            <img src={arrowBack} className={s.imgArrow} />
                            {t("paymentMethods.backBtn")}</button>
                        <h2 className={s.title}>{t("paymentMethods.title")}</h2>
                        <p>{t("paymentMethods.subtitle1")}</p>
                        <p>{t("paymentMethods.subtitle2")}</p>
                    </div>
                    <ul className={s.wrapMethods}>
                        {methods.map((method, index) =>
                        <li key={index} className={s.containerMethod}>
                            <div className={s.wrapCardsTitle}>
                                <h3 className={s.titleCard}>{method.title}</h3>
                                <p className={s.description}>{method.description}</p>
                            </div>
                            <MyBtnOrange name={method.btn} style={{width: "100%", height: "40px", margin: "15px 0"}}
                              onClick={() => navigate(`/payment-methods/${method.name}`)}
                            />
                            <h5 className={s.cardSubtitle}>{method.global}</h5>
                            <span className={s.cardText}>{method.globalText1}</span>
                            <span className={s.cardText}>{method.globalText2}</span>
                            <span className={s.cardText}>{method.globalText3}</span>
                            <span className={s.cardText}>{method.globalText4}</span>
                            <h5 className={s.cardSubtitle}>{method.USCanada}</h5>
                            <span className={s.cardText}>{method.USCanadaText1}</span>
                            <span className={s.cardText}>{method.USCanadaText2}</span>
                            <h5 className={s.cardSubtitle}>{method.europe}</h5>
                            <span className={s.cardText}>{method.europeText1}</span>
                            <span className={s.cardText}>{method.europeText2}</span>
                            <span className={s.cardText}>{method.europeText3}</span>
                            <h5 className={s.cardSubtitle}>{method.asia}</h5>
                            <span className={s.cardText}>{method.asiaText1}</span>
                            <span className={s.cardText}>{method.asiaText2}</span>
                            <span className={s.cardText}>{method.asiaText3}</span>
                        </li>
                        )}
                    </ul>
                </div>
                </div>
                <Footer/>
            </div>
        </>
    )
}

import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import { Button, Steps } from 'antd';
import {ChooseBankSeparateLinkP2P} from "./ChooseBankSeparateLinkP2P";
import {Loader} from "../P2P/Loader";
import {setIsLoading} from "../../../../store/slices/AcquiringSlices/DataReducer";
import {Response} from "../P2P/Response";
import s from "../P2P/P2P.module.css";
import '../P2P/index.css'
import {DataInputSeparateLinkP2P} from "./DataInputSeparateLinkP2P";
import {ReplenishmentSeparateLinkP2P} from "./ReplenishmentSeparateLinkP2P";
export const SeparateLinkP2P = () => {
    const dispatch = useDispatch();
    const location = useLocation()
    const {isLoading} = useSelector((store) => store.data);
    const methods = useSelector(store => store.accountP2P.apiKey.methods)
    const [current, setCurrent] = useState(0);
    const [selectedCardId, setSelectedCardId] = useState(null);
    const [methodId, setMethodId] = useState(null);
    const [isSuccess, setIsSuccess] = useState(false)
    const [data, setData] = useState({
        amount: '',
        name: ''
    })
    const selectedCard = useMemo(
        () => methods?.find((el) => el.id === selectedCardId),
        [selectedCardId, methods]
    );
    const next = () => {
        dispatch(setIsLoading(true))
        setCurrent(current + 1);
        setTimeout(() => {
            dispatch(setIsLoading(false))
        }, 2000);
    };
    const prev = () => {
        setCurrent(current - 1);
    };
    const start = () => {
        setCurrent(current - 3);
    }
    const steps = [
        {
            title: '01',
            content: <ChooseBankSeparateLinkP2P selectedCard={selectedCard} setMethodId={setMethodId} selectedCardId={selectedCardId} setSelectedCardId={setSelectedCardId} next={next} />,
        },
        {
            title: '02',
            content: <DataInputSeparateLinkP2P methodId={methodId} data={data} setData={setData} prev={prev} next={next} selectedCardId={selectedCardId}/>,
        },
        {
            title: '03',
            content: <ReplenishmentSeparateLinkP2P selectedCard={selectedCard} selectedCardId={selectedCardId} data={data} setData={setData} setIsSuccess={setIsSuccess} prev={prev} next={next} />,
        },
        {
            title: '04',
            content: <Response isSuccess={isSuccess} />,
        },
    ];

    const items = steps.map((item) => ({
        key: item.title,
        title: item.title,
    }));
    const contentStyle = {
        marginTop: 40,
        fontSize: 20,
        color: '#ABABAB',
        fontFamily: 'Cera Pro',
    };

    useEffect(() => {
        if (location.pathname.includes('/api/p2p')) {
            dispatch(setIsLoading(false))
            return;
        }
    }, []);

    return (
        <div className={s.wrap}>
            <Steps current={current} items={items} progressDot={true}/>
            <div style={contentStyle}>{steps[current].content}</div>
            <div
                style={{
                    marginTop: 24,
                }}
            >
                {(current > 0 && current < 3 ) &&
                    <Button
                        style={{
                            margin: '0 8px',
                        }}
                        onClick={() => prev()}
                    >
                        Назад
                    </Button>
                }
                {isLoading &&
                    <div className={s.loader}><Loader /></div>}
                {current === steps.length - 1 && (
                    <Button type="primary" onClick={() => start()}>
                        В начало
                    </Button>
                )}
            </div>
        </div>
    )
}

import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Layout} from 'antd';
import AccountTransactionsTableP2P from './AccountTransactionsTableP2P';
import TransactionDetailsModal from '../../../../ui/modals/TransactionDetailModal/TransactionDetailsModal';
import { sortedP2PTransactions,
    sortedWithdrawalsTransactions
} from "../../../../store/slices/selectors";
import {setSearchP2P} from "../../../../store/slices/P2PSlices/DataP2PReducer";
import CustomTab from "../../../../ui/CustomTab/CustomTab";
import Statement from "../../../../ui/Statement/Statement";
import MobileFilter from "../../../../ui/MobileFilter/MobileFilter";
import {SearchInput} from "../../../../ui/SearchInput/SearchInput";
import style from './AccountP2PTransactions.module.css';
import './styles.css';

const AccountP2PTransactions = (props) => {
    const dispatch = useDispatch()
    const {t} = useTranslation();
    const {isDetailsPopupShow} =
        useSelector((store) => store.accounts);
    const {searchP2P} =
        useSelector((store) => store.dataP2P);
    const transactions = useSelector(sortedP2PTransactions)
    const withdrawalTransactions = useSelector(sortedWithdrawalsTransactions)

    const btns = [
        {
            name: t('personal.accountList.accountTransactions'),
            key: 'PersonalAccountTransactions',
        },
        {
            name: t('personal.accountList.listOfWithdrawals'),
            key: 'ListOfWithdrawals',
        },
    ];

    useEffect(() => {
        setDisplayedBtns(btns);
    }, [t]);

    const [displayedBtns, setDisplayedBtns] = useState(btns);
    const [displayedData, setDisplayedData] = useState(
        'PersonalAccountTransactions'
    );
    useEffect(() => {
        return ()=> dispatch(setSearchP2P(''))
    },[])
    const changeActiveBtnOnClick = (key) => {
        setDisplayedData(key);
    };
    const getDisplayedData = () => {
        if (displayedData === 'PersonalAccountTransactions') {
            return (
                <AccountTransactionsTableP2P
                    isTransactions={true}
                    tableData={transactions}
                />
            );
        }
        if (displayedData === 'ListOfWithdrawals') {
            return <AccountTransactionsTableP2P tableData={withdrawalTransactions}/>;
        }
    };
    const onChangeHandler = (e) => {
        dispatch(setSearchP2P(e.target.value))
    }

    return (
        <Layout>
            <TransactionDetailsModal isDetailsPopupShow={isDetailsPopupShow}/>
            <div className={style.content}>
                <div className={style.title}>
                    {t('personal.accountList.transactions')}
                    <p className={style.count}>({displayedData === 'PersonalAccountTransactions' ? transactions?.length : withdrawalTransactions?.length})</p>

                </div>
                <div className={style.searchWrapper}><SearchInput placeholder={'Search...'}  value={searchP2P} onChange={onChangeHandler}/></div>
                <div className={style.tables_nav_wrapper}>
                    {displayedBtns.map((btn) => (
                        <CustomTab
                            key={btn.key}
                            active={
                                btn.key === displayedData
                            }
                            onClick={() => changeActiveBtnOnClick(btn.key)}
                            name={btn.name}
                        />
                    ))}
                </div>
                <div className={style.table_wrapper}>
                    {displayedData === 'PersonalAccountTransactions' &&
                        <div className={style.statementWrapper}><Statement walletStatement/>
                            <div className={style.mobileFilterWrapper}><MobileFilter walletFilter/></div>
                        </div>}
                    {getDisplayedData()}
                </div>
            </div>
        </Layout>
    );
};

export default AccountP2PTransactions;

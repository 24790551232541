import React, {useEffect, useMemo, useState} from 'react';
import { useSelector, useDispatch} from 'react-redux';
import {Layout} from 'antd';
import {Loader} from '../../../../ui/Loader/Loader';
import {CryptoCard} from "../../../../components/Crypto/CryptoCard/CryptoCard";
import s from './Acquiring.module.css';
import {useTranslation} from "react-i18next";
import {SearchInput} from "../../../../ui/SearchInput/SearchInput";
import CustomTab from "../../../../ui/CustomTab/CustomTab";
import TransactionTable from "../../../../components/Crypto/TransactionTable/TransactionTable";
import AjaxCryptoHelper from "../../../../ajax/AjaxCryptoHelper";
import AddressesTable from "../../../../components/Crypto/AddressesTable";
import JsTableHelper from "../../../../utils/JsTableHelper";

const Acquiring = () => {
    const {
        addresses,
        acquiringTransactions,
        acquiringTransactionsInfo,
        selectedCard, // wallet from crypto backend
    } = useSelector((store) => store.cryptoService);
    const {t} = useTranslation();

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [isTransactionTable, setIsTransactionTable] = useState(true);
    const [selectedTransactions, setSelectedTransactions] = useState(acquiringTransactions);
    const [selectedAddresses, setSelectedAddresses] = useState(addresses)

    const isMobileVersion = window.innerWidth <= 768;

    const filterEffect = () => {
        if (selectedCard) {
            if (isTransactionTable) {
                JsTableHelper.filterTransactions(acquiringTransactions, selectedCard, setSelectedTransactions);
            }
            else {
                JsTableHelper.filterAddresses(addresses, selectedCard, setSelectedAddresses)
            }
        }
    }

    useEffect(async () => {
        setLoading(true)
        await dispatch(await AjaxCryptoHelper.getReduxDynamicTransactions());
        await dispatch(await AjaxCryptoHelper.getReduxDynamicAddresses());
        setLoading(false)
    }, []);

    useEffect(() => {
        filterEffect()
    }, [selectedCard]);

    return (
        <Layout className={s.layout}>
            {loading ? (
                <Loader/>
            ) : (
                <>
                    <div className={s.walletsWrapper}>
                        {acquiringTransactionsInfo?.map((w) => (
                            <CryptoCard
                                crypto
                                key={w.id}
                                wallet={w}
                            />
                        ))}
                    </div>
                    <div className={s.preTableWidgets}>
                        <span className={s.txsTitle}>
                            {t('personal.accountList.transactions')}
                            <span className={s.txsCount}>({acquiringTransactions?.length})</span>
                        </span>
                        <span className={s.txsTitle}>
                            All addresses
                            <span className={s.txsCount}>({addresses?.length})</span>
                        </span>

                        <div className={s.searchWrapper}>
                            <SearchInput placeholder="find by TxID" onInput={(e) => JsTableHelper.findItem(
                                acquiringTransactions,
                                'txid',
                                e.target.value,
                                setSelectedTransactions
                            )}/>
                        </div>

                        <div className={s.tableSwitcher}>
                            <CustomTab
                                key="AcquiringTransactions"
                                active={isTransactionTable}
                                onClick={() => {
                                    setSelectedTransactions(acquiringTransactions);
                                    setIsTransactionTable(true)
                                }}
                                name={t('crypto.acTxs')}
                            />
                            <CustomTab
                                key="AcquiringAddresses"
                                active={!isTransactionTable}
                                onClick={() => {
                                    setSelectedAddresses(addresses)
                                    setIsTransactionTable(false)
                                }}
                                name={t('crypto.acAddrs')}
                            />
                        </div>
                    </div>
                    {isTransactionTable
                        ? <TransactionTable mobile={isMobileVersion} transactions={selectedTransactions}/>
                        : <AddressesTable mobile={isMobileVersion} addresses={selectedAddresses}/>
                    }
                </>
            )}
        </Layout>
    );
};

export default Acquiring;


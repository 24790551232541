import React, {useEffect, useMemo, useState} from 'react';
import { useSelector, useDispatch} from 'react-redux';
import {Layout} from 'antd';
import {Loader} from '../../../../ui/Loader/Loader';
import {CryptoCard} from "../../../../components/Crypto/CryptoCard/CryptoCard";
import s from './Accounts.module.css';
import {useTranslation} from "react-i18next";
import {SearchInput} from "../../../../ui/SearchInput/SearchInput";
import CustomTab from "../../../../ui/CustomTab/CustomTab";
import TransactionTable from "../../../../components/Crypto/TransactionTable/TransactionTable";
import AjaxCryptoHelper from "../../../../ajax/AjaxCryptoHelper";
import MobileTransactionTable from "../../../../components/Crypto/TransactionTable/MobileTransactionTable";
import JsTableHelper from "../../../../utils/JsTableHelper";

const Accounts = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const isMobileVersion = window.innerWidth <= 768;

    // store properties
    const {transactions, wallets, selectedCard} = useSelector((store) => store.cryptoService);

    // state vars
    const [loading, setLoading] = useState(false);
    const [selectedTransactions, setSelectedTransactions] = useState(transactions);

    // transaction caching
    useMemo(() => setSelectedTransactions(transactions), [transactions]);

    // local functions
    const withdrawalHandler = () => {}

    const filterTransactions = () => {
        if (selectedCard && transactions.length) {
            JsTableHelper.filterTransactions(transactions, selectedCard, setSelectedTransactions);
        }
        else {
            setSelectedTransactions(transactions)
        }
    }

    useEffect(async() => {
        try {
            setLoading(true);
            await dispatch(await AjaxCryptoHelper.getReduxAccountWalletsAndTransactions());
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setLoading(false)
        }

    }, []);

    useEffect( () => {
        filterTransactions()
    }, [selectedCard]);

    return (
      <Layout className={s.layout}>
        {loading ? (
            <Loader/>
        ) : (
            <>
                <div className={s.walletsWrapper}>
                    {wallets?.map((w) => (
                        <CryptoCard
                            crypto
                            key={w.id}
                            wallet={w}
                            withdrawalHandler={withdrawalHandler}
                        />
                    ))}
                </div>
                <div className={s.preTableWidgets}>
                    <span className={s.txsTitle}>
                    {t('personal.accountList.transactions')}
                        <span className={s.txsCount}>({transactions?.length})</span>
                    </span>

                    <div className={s.searchWrapper}>
                        <SearchInput onInput={(e) => JsTableHelper.findItem(
                            transactions,
                            'txid',
                            e.target.value,
                            setSelectedTransactions
                        )} placeholder={'Find by TxID'} />
                    </div>

                    <div className={s.tableSwitcher}>
                        <CustomTab
                            key="PersonalAccountTransactions"
                            active={true}
                            onClick={() => setSelectedTransactions(transactions)}
                            name={t('personal.accountList.accountTransactions')}
                        />
                        <CustomTab
                            key="ListOfWithdrawals"
                            active={false}
                            //onClick={() => changeActiveBtnOnClick(btn.key)}
                            name={t('personal.accountList.listOfWithdrawals')}
                        />
                    </div>
                </div>
                {isMobileVersion
                    ? <MobileTransactionTable transactions={selectedTransactions ?? transactions}/>
                    : <TransactionTable transactions={selectedTransactions ? selectedTransactions : transactions}/>
                }

            </>
        )}
      </Layout>
  );
};

export default Accounts;

import React from 'react';
import {NavLink} from "react-router-dom";
import s from './PrivacyPolicy.module.css'
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import {useTranslation} from "react-i18next";
import bgOne from './../../assets/img/privacyPolicy_left.svg';
import bgTwo from '../../assets/img/privacyPolicy_left_two.svg'
import bgThree from '../../assets/img/privacyPolicy_right_two.svg'
import arrowBack from "../../assets/img/arrow_back.svg";
import {useNavigate} from "react-router";

export const PrivacyPolicy = () => {
  const {t} = useTranslation()
  const navigate = useNavigate();

  return (
      <>
        <img  src={bgOne} className={s.bgOne}/>
        <img  src={bgTwo} className={s.bgTwo} />
        <img  src={bgThree} className={s.bgThree}/>
        <img  src={bgOne} className={s.bgFour}/>
        <div className={s.wrapper}>
          <Header/>
          <div className={s.hero}>
          <div className={s.container}>
            <div className={s.content}>
              <div>
                <div className={s.fragmentOne}/>
                <div className={s.fragmentTwo}/>
                <div className={s.fragmentThree}/>
                <div className={s.fragmentFour}/>
              </div>
              <button className={s.buttonBack} onClick={() => navigate('/')}>
                <img src={arrowBack} className={s.imgArrow} />
                {t("paymentMethods.backBtn")}</button>
              <h2 className={s.title}>{t("privacy.title")}</h2>
              <p>{t("privacy.1")}</p>
              <p>{t("privacy.2")}</p>
            </div>

            <div className={s.content}>
              <h2 className={s.content_title}><p>{t("privacy.3")}</p></h2>
              <p>{t("privacy.4")}</p>
              <p>{t("privacy.5")}</p>
              <p>{t("privacy.6")}</p>
              <p>{t("privacy.7")}</p>

              <div>
                <p>
                  {t("privacy.8")}
                </p>
                <p>
                  {t("privacy.9")}
                </p>
                <p>
                  {t("privacy.10")}
                </p>
                <p>
                  {t("privacy.11")}
                </p>
                <p>
                  {t("privacy.12")}
                </p>
                <p>
                  {t("privacy.13")}
                </p>
              </div>

              <p>{t("privacy.14")}</p>
              <p>{t("privacy.15")}</p>
            </div>
            <div className={s.content}>
              <h2 className={s.content_title}>{t("privacy.16")}</h2>
              <p>{t("privacy.17")}</p>
              <p>{t("privacy.18")}</p>
              <p>{t("privacy.19")}</p>
              <p>{t("privacy.20")}</p>
              <p>{t("privacy.21")}</p>
              <p>{t("privacy.22")}</p>
              <p>{t("privacy.23")}</p>
              <p>{t("privacy.24")}</p>
              <p>{t("privacy.25")}</p>
              <p>{t("privacy.26")}</p>
            </div>

            <div className={s.content}>
              <h2 className={s.content_title}>{t("privacy.27")}</h2>
              <p>{t("privacy.28")}</p>
              <p>{t("privacy.29")}</p>
              <p>{t("privacy.30")}</p>
              <p>{t("privacy.31")}</p>
              <p>{t("privacy.32")}</p>
              <p>{t("privacy.33")}</p>
              <p>{t("privacy.34")}</p>
            </div>

            <div className={s.content}>
              <h2 className={s.content_title}>{t("privacy.35")}</h2>
              <p>{t("privacy.36")}</p>
              <p>{t("privacy.37")}</p>
              <p>{t("privacy.38")}</p>
              <p>{t("privacy.39")}</p>
            </div>

            <div className={s.content}>
              <h2 className={s.content_title}>{t("privacy.40")}</h2>
              <p>{t("privacy.41")}</p>
            </div>

            <div className={s.content}>
              <h2 className={s.content_title}>{t("privacy.42")}</h2>
              <p>{t("privacy.43")}</p>
            </div>

            <div className={s.content}>
              <h2 className={s.content_title}>{t("privacy.44")}</h2>
              <p>{t("privacy.45")}</p>
              <p>{t("privacy.46")}</p>
            </div>
          </div>
          </div>
          <Footer/>
        </div>
      </>
  )
}

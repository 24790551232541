import React from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import s from "../../PrivacyPolicy/PrivacyPolicy.module.css";
import arrowBack from "../../../assets/img/arrow_back.svg";

export const BankTransfers = () => {
    const {t} = useTranslation()
    const navigate = useNavigate();

    return (
            <div className={s.wrapper}>
                <Header/>
                <div className={s.hero}>
                    <div className={s.container}>
                        <div className={s.content}>
                            <div>
                                <div className={s.fragmentOne}/>
                                <div className={s.fragmentTwo}/>
                                <div className={s.fragmentThree}/>
                                <div className={s.fragmentFour}/>
                            </div>
                            <button className={s.buttonBack} onClick={() => navigate('/payment-methods')}>
                                <img src={arrowBack} className={s.imgArrow} />
                                {t("paymentMethods.backBtn")}</button>
                            <h2 className={s.title}>{t("paymentMethods.bankTransfer")}</h2>
                            <p>{t("bankTransfer.description")}</p>
                        </div>
                        <div className={s.content}>
                            <h2 className={s.content_title}><p>{t("bankTransfer.subtitle1")}</p></h2>
                            <p>{t("bankTransfer.text1")}</p>
                        </div>
                        <div className={s.content}>
                            <h2 className={s.content_title}>{t("bankTransfer.subtitle2")}</h2>
                            <p>{t("bankTransfer.text2")}</p>
                            <span>{t("bankTransfer.text3")}</span>
                            <span>{t("bankTransfer.text4")}</span>
                        </div>
                        <div className={s.content}>
                            <h2 className={s.content_title}>{t("bankTransfer.subtitle3")}</h2>
                            <p>{t("bankTransfer.text5")}</p>
                            <span>{t("bankTransfer.text6")}</span>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
    )
}

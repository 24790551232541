import React, {useEffect, useState} from 'react';
import style from './AccountTransactions.module.css';
import './styles.css';
import AccountTransactionsTable from './AccountTransactionsTable/AccountTransactionsTable';
import TransactionDetailsModal from '../../../../ui/modals/TransactionDetailModal/TransactionDetailsModal';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Layout} from 'antd';
import {sortedAccountsTransactions, sortedWithdrawalsTransactions} from "../../../../store/slices/selectors";
import CustomTab from "../../../../ui/CustomTab/CustomTab";
import Statement from "../../../../ui/Statement/Statement";
import MobileFilter from "../../../../ui/MobileFilter/MobileFilter";
import {SearchInput} from "../../../../ui/SearchInput/SearchInput";
import {setSearch} from "../../../../store/slices/AcquiringSlices/DataReducer";

const AccountTransactions = (props) => {
  const dispatch = useDispatch()
  const {t} = useTranslation();
  const {isDetailsPopupShow} =
      useSelector((store) => store.accounts);
  const {search} =
      useSelector((store) => store.data);
  const accountTransactions = useSelector(sortedAccountsTransactions)
  const withdrawalTransactions = useSelector(sortedWithdrawalsTransactions)

  const btns = [
    {
      name: t('personal.accountList.accountTransactions'),
      key: 'PersonalAccountTransactions',
    },
    {
      name: t('personal.accountList.listOfWithdrawals'),
      key: 'ListOfWithdrawals',
    },
  ];

  useEffect(() => {
    setDisplayedBtns(btns);
  }, [t]);

  const [displayedBtns, setDisplayedBtns] = useState(btns);
  const [countryList, setCountryList] = useState([]);
  const [displayedData, setDisplayedData] = useState(
      'PersonalAccountTransactions'
  );

  useEffect(() => {
    setCountryList(props.accounts);
  }, [props.accounts]);

  useEffect(() => {
    return ()=> dispatch(setSearch(''))
  },[])

  const changeActiveBtnOnClick = (key) => {
    setDisplayedData(key);
  };

  const getDisplayedData = () => {
    if (displayedData === 'PersonalAccountTransactions') {
      return (
          <AccountTransactionsTable
              isTransactions={true}
              tableData={accountTransactions}
          />
      );
    }

    if (displayedData === 'ListOfWithdrawals') {
      return <AccountTransactionsTable tableData={withdrawalTransactions}/>;
    }

  };

  const onChangeHandler = (e) => {
    dispatch(setSearch(e.target.value))
  }

  return (
      <Layout>
        <TransactionDetailsModal isDetailsPopupShow={isDetailsPopupShow}/>
        <div className={style.content}>
          <div className={style.title}>
            {t('personal.accountList.transactions')}
            <p className={style.count}>({displayedData === 'PersonalAccountTransactions' ? accountTransactions?.length : withdrawalTransactions?.length})</p>

          </div>
          <div className={style.searchWrapper}><SearchInput  value={search} onChange={onChangeHandler}/></div>
          <div className={style.tables_nav_wrapper}>
            {displayedBtns.map((btn) => (
                <CustomTab
                    key={btn.key}
                    active={
                        btn.key === displayedData
                    }
                    onClick={() => changeActiveBtnOnClick(btn.key)}
                    name={btn.name}
                />
            ))}
          </div>
          <div className={style.table_wrapper}>
            {displayedData === 'PersonalAccountTransactions' &&
                <div className={style.statementWrapper}><Statement walletStatement/>
                  <div className={style.mobileFilterWrapper}><MobileFilter walletFilter/></div>
                </div>}
            {getDisplayedData()}
          </div>
        </div>
      </Layout>
  );
};

export default AccountTransactions;

import React, {useEffect, useState} from 'react';
import {Link, Outlet} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import {useTranslation} from 'react-i18next';
import {Layout, Menu} from 'antd';
import Header from '../../../components/Crypto/Header';

import {
    AccountsSvg,
    AcquiringTransactionsSvg,
    ApiSettingsSvg,
    RollingReserveSvg
} from '../../../utils/sprite';
import logo from "../../../assets/img/logo.svg";
import s from './PersonalAccount.module.css';

import {logoutUser} from "../../../store/slices/Crypto/CommonReducer";
import Burger from "../../../components/Burger/Burger";

const PersonalAccount = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const isMobileVersion = window.innerWidth <= 768;

    const [collapsed, setCollapsed] = useState(true); // for mobile navigation menu
    const {token} = useSelector((store) => store.cryptoService);

    const mobileSiderStyle = {
        width: collapsed ? 0 : '60%',
        backgroundColor: '#3F3F3F',
        padding: 0
    }

    const desktopLayoutStyle = {
        gridTemplateColumns: "20% 80%"
    }

    const mobileLayoutStyle = {
        gridTemplateColumns: "0% 100%",
    }

    const navMenuItems = [
        {
            label: 'Accounts',
            icon: <AccountsSvg />,
            link: '/crypto/accounts'
        },
        {
            label: 'Acquiring',
            icon: <AcquiringTransactionsSvg />,
            link: '/crypto/acquiring'
        },
        /*{
            label: 'Rolling reserve',
            icon: <RollingReserveSvg active={false} />,
            link: '#'
        },*/
        {
            label: 'API settings',
            icon: <ApiSettingsSvg />,
            link: '/crypto/settings'
        },
    ];

    useEffect(() => {
        if (token === false || token === '') {
            navigate('/');
        }
    }, [token]);

    return (
        <Layout
            className={s.layout}
            style={isMobileVersion ? mobileLayoutStyle : desktopLayoutStyle}
        >

            <Header className={s.header} onLogout={logoutUser} mobile={isMobileVersion}/>

            <div className={s.aside} style={isMobileVersion ? mobileSiderStyle : {}} >
                <Menu class={s.menu} mode='inline'>
                    {navMenuItems.map((item, index) => (
                        <Menu.Item
                            key={index + 1}
                            onClick={() => navigate(item.link)}
                            className={s.menuItem}
                            icon={item.icon}
                        >
                            {item.label}
                        </Menu.Item>
                    ))}
                </Menu>

                <div className={s.coDetails}>
                    <div className={s.bingo}>BingoPay</div>
                    <div className={s.copyright}>
                        <span>Copyright © 2023</span>
                        <span> All rights reserved</span>
                    </div>
                </div>
            </div>

            <div className={s.logoWrapper}>
                <Link to={"/"}>
                    <img width={31} height={36} src={logo} alt=""/>
                </Link>
                <Link to={"/"} className={s.logoTitle}>
                    <span>BingoPay</span>
                </Link>
                <Burger hidden={!isMobileVersion} collapsed={collapsed} onToggle={() => setCollapsed(!collapsed)}/>
            </div>


            <div className={s.main} onClick={() => setCollapsed(true)}>
                <div className={s.mainWrapper}>
                    <Outlet/>
                </div>
            </div>
        </Layout>
    );
};

export default PersonalAccount;

import React, {useEffect, useState} from 'react';
import s from './FiatAccounts.module.css';
import {AuthAPI} from '../../../ajax/Api';
import WithdrawalConfirmModal from '../../../ui/modals/WithdrawalConfirmModal/WithdrawalConfirmModal';
import {Layout} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import {Loader} from '../../../ui/Loader/Loader';
import {getAccountsTransactions, getWithdrawalsTransactions,} from '../../../ajax/services/accounts';
import AccountTransactions from "../AccountAcquiring/AccountTransactions/AccountTransactions";
import {AccountCardV2} from "../Cards/AccountCardV2/AccountCardV2";


const FiatAccounts = () => {
  const dispatch = useDispatch();

  const {token} = useSelector((store) => store.auth);
  const {isLoading} = useSelector((store) => store.data);

  const [accounts, setAccounts] = useState([]);
  const [isTest, setIsTest] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [disabled, setIsDisabled] = useState(false);
  const [status, setStatus] = useState(0);

  function withdrawalHandler(accNumber) {
    setIsDisabled(true);

    AuthAPI.createWithdrawalToBankAccount(token, accNumber)
        .then((res) => {
          setStatus(res.status);
        })
        .catch((e) => {
          setStatus(e.status);
        })
        .finally(() => {
          dispatch(getAccountsTransactions(token));
          dispatch(getWithdrawalsTransactions(token));
          setIsDisabled(false);
          setShowConfirmModal(true);
        });
  }

  useEffect(() => {
    AuthAPI.getAccountsList(token).then((res) => {
      setAccounts(res.data.result.data);
      setIsTest(res.data.result.isTest);
    });
  }, []);

  useEffect(() => {
    dispatch(getAccountsTransactions(token));
    dispatch(getWithdrawalsTransactions(token));
  }, []);


  return (
      <Layout className={s.layout}>
        <WithdrawalConfirmModal
            status={status}
            showConfirmModal={showConfirmModal}
            showPopUp={setShowConfirmModal}
        />
        {isLoading ? (
            <Loader/>
        ) : (
            <>
              <div className={s.accounts_list_wrapper}>
                {accounts &&
                    accounts?.map((acc) => (
                        <AccountCardV2
                            key={acc.accountNumber}
                            acc={acc}
                            crypto={false}
                            isTest={isTest}
                            disabled={disabled}
                            withdrawalHandler={withdrawalHandler}
                        />
                    ))}
              </div>
              <AccountTransactions accounts={accounts}/>
            </>
        )}
      </Layout>
  );
};

export default FiatAccounts;

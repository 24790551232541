import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import s from "./Cards.module.css";
import arrowBack from "../../../assets/img/arrow_back.svg";
import classNames from "classnames";
import bgOne from "../../../assets/img/privacyPolicy_left.svg";
import bgTwo from "../../../assets/img/privacyPolicy_left_two.svg";
import bgThree from "../../../assets/img/privacyPolicy_right_two.svg";
import heroImage from "../../../assets/img/new/hero.svg";

export const Cards = () => {
    const {t} = useTranslation()
    const navigate = useNavigate();
    const data = [
        {
            key: t("cardsPage.table6"),
            stripeAccount: t("cardsPage.allCountries"),
            customerCountry: t("cardsPage.global"),
            secureAuth: t("cardsPage.yes"),
            wallets: t("cardsPage.yes"),
            isSecureAuth: true,
            isWallets: true
        },
        {
            key: t("cardsPage.table7"),
            stripeAccount: t("cardsPage.allCountries"),
            customerCountry: t("cardsPage.global"),
            secureAuth: t("cardsPage.yes"),
            wallets: t("cardsPage.yes"),
            isSecureAuth: true,
            isWallets: true
        },
        {
            key: t("cardsPage.table8"),
            stripeAccount: t("cardsPage.allCountries2"),
            customerCountry: t("cardsPage.globalExcept"),
            secureAuth: t("cardsPage.yes"),
            wallets: t("cardsPage.yes"),
            isSecureAuth: true,
            isWallets: true
        },
        {
            key: t("cardsPage.table9"),
            stripeAccount: t("cardsPage.allCountries3"),
            customerCountry: t("cardsPage.global"),
            secureAuth: t("cardsPage.yes"),
            wallets: t("cardsPage.yes"),
            isSecureAuth: true,
            isWallets: true
        },
        {
            key: t("cardsPage.table10"),
            stripeAccount: t("cardsPage.allCountries4"),
            customerCountry: t("cardsPage.global"),
            secureAuth: t("cardsPage.yes"),
            wallets: t("cardsPage.notSupported"),
            isSecureAuth: true
        },
        {
            key: t("cardsPage.table11"),
            stripeAccount: t("cardsPage.allCountries5"),
            customerCountry: t("cardsPage.global"),
            secureAuth: t("cardsPage.allCountries7"),
            wallets: t("cardsPage.yes"),
            isWallets: true
        },
        {
            key: t("cardsPage.table12"),
            stripeAccount: t("cardsPage.allCountries6"),
            customerCountry: t("cardsPage.france"),
            secureAuth: t("cardsPage.yes"),
            wallets: t("cardsPage.notSupported"),
            isSecureAuth: true
        },
        {
            key: t("cardsPage.table13"),
            stripeAccount: t("cardsPage.australia"),
            customerCountry: t("cardsPage.australia"),
            secureAuth: t("cardsPage.notSupported"),
            wallets: t("cardsPage.notSupported")
        },
    ]

    const cat = ['stripeAccount', 'customerCountry', 'secureAuth', 'wallets']


    const threshold = 100;

    const [selectedColumn, setSelectedColumn] = useState(0)
    const [pageXstart, setPageXstart] = useState(0)

    let view = cat[selectedColumn]

    const startTouch = (e) => {
        e.preventDefault()
        setPageXstart(0);
        const touch = e.changedTouches[0];
        setPageXstart(touch.pageX);
    }

    const endTouch = (e) => {
        e.preventDefault()
        let touch = e.changedTouches[0];
        let distX = touch.pageX - pageXstart;
        if (Math.abs(distX) >= threshold && distX < 0) {
            {
                selectedColumn < 3 && setSelectedColumn(prevState => prevState + 1)
            }
        }
        if (Math.abs(distX) >= threshold && distX > 0) {
            {
                selectedColumn > 0 && setSelectedColumn(prevState => prevState - 1)
            }
        }
    }

    const handleMenuNavigation = (direction) => {
        if(direction==='right' && selectedColumn < 3){
            setSelectedColumn(prevState => prevState + 1)
        }
        if(direction==='left' && selectedColumn > 0){
            setSelectedColumn(prevState => prevState -1)
        }
    }

    return (
        <>
            <div className={s.wrapper}>
                <Header/>
                <div className={s.hero}>
                    <div className={s.container}>
                        <div>
                            <div>
                                <div className={s.fragmentOne}/>
                                <div className={s.fragmentTwo}/>
                                <div className={s.fragmentThree}/>
                                <div className={s.fragmentFour}/>
                            </div>
                            <button className={s.buttonBack} onClick={() => navigate('/payment-methods')}>
                                <img src={arrowBack} className={s.imgArrow} />
                                {t("paymentMethods.backBtn")}</button>
                            <div className={s.wrapMobileTitle}>
                                <h2 className={s.title}>{t("paymentMethods.cards")}</h2>
                                <div className={s.buttonsBlock}>
                                    <button  onClick={()=>handleMenuNavigation('left')} className={classNames(s.menuButton, {
                                        [s.unactive]: selectedColumn === 0,
                                    })}></button>
                                    <button onClick={()=>handleMenuNavigation('right')} className={classNames(s.menuButton, {
                                        [s.unactive]: selectedColumn === 3,
                                    })}></button>
                                </div>
                            </div>
                            <p className={s.subtitle}>{t("cardsPage.title")}</p>
                            <p className={s.text}>{t("cardsPage.subtitle")}</p>
                        </div>
                        <div className={s.table}>
                            <div style={{transform: `translateX(${-240 * selectedColumn}px)`}} className={s.tableHeader}>
                                <div className={s.headerItem}>
                                    {t('cardsPage.table1')}
                                </div>
                                <div className={s.headerItem}>
                                    {t('cardsPage.table2')}
                                </div>
                                <div className={s.headerItem}>
                                    {t('cardsPage.table3')}
                                </div>
                                <div className={s.headerItem}>
                                    {t('cardsPage.table4')}
                                </div>
                                <div className={s.headerItem}>
                                    {t('cardsPage.table5')}
                                </div>
                            </div>
                            <div className={s.tableBodyWrapper}>
                                {
                                    data.map((item, index) => {
                                        return (
                                            <div key={index} className={classNames(s.row, {[s.bottomBorderSider]: index === data.length - 1,
                                                [s.desktopHide]: index === 15
                                            })}>
                                                <div className={classNames(s.siderItem, {
                                                    [s.bottomBorderSider]: index === data.length - 1,

                                                })
                                                }>{item.key}</div>
                                                <div className={classNames(s.rowItem, {
                                                    [s.topLeftBorder]: index === 0,
                                                    [s.bottomLeftBorder]: index === data.length - 1,
                                                    [s.oneItem]: Object.keys(item).length === 2,
                                                    [s.shadow]: true,
                                                })}>{item.stripeAccount}
                                                </div>
                                                {item.customerCountry && <div className={classNames(`${s.rowItem} ${s.border}`)}>{item.customerCountry}
                                                </div>}
                                                {item.secureAuth && <div className={classNames(`${s.rowItem} ${s.border}`, item.isSecureAuth && s.green )}>{item.secureAuth}
                                                </div>}
                                                {item.wallets && <div
                                                    className={classNames(s.rowItem, {
                                                        [s.topRightBorder]: index === 0,
                                                        [s.bottomRightBorder]: index === data.length - 1,
                                                        [s.border]: true
                                                    }, item.isWallets && s.green)}>{item.wallets}
                                                </div>}
                                            </div>)
                                    })
                                }
                            </div>


                            <div className={classNames(s.mobileTableBodyWrapper,
                                view === 'stripeAccount' ? s.topMobileBorder : '',
                                view === 'customerCountry' ? s.mobileBorder : '',
                                view === 'secureAuth' ? s.mobileBorder : '',
                                view === 'wallets' ? s.bottomMobileBorder : '',
                            )}
                                 onTouchEnd={(e) => endTouch(e)
                                 }
                                 onTouchStart={(e) => startTouch(e)}
                            >
                                {
                                    data.map((item, index) => {
                                        return (
                                            <div key={index} className={s.mobileTableItem}>
                                                <div className={s.mobileItemTitle}>
                                                    {item.key}
                                                </div>
                                                <div className={s.mobileItemSubtitle}>
                                                    {item[view] ? item[view] : item['stripeAccount']}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>


                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        </>
    )
}

import {useSelector} from "react-redux";
import s from "./P2P.module.css";

export const Loader = () => {
    const {firstNameP2P, lastNameP2P } = useSelector(store=>store.authP2P.data)
    return (
        <>
            <div className={s.headerWrap}>
                <div className={s.chooseBank}>
                    <div className={s.iconLoader}></div>
                    <div>
                        <p className={s.title}>Подождите минутку...</p>
                        <span className={s.name}>Идет процесс создания платежа</span>
                    </div>
                </div>
                <div className={s.name}>Вы пополняете баланс аккаунта на {`${firstNameP2P} ${lastNameP2P}`}</div>
            </div>
            <div className={s.logoLoader}></div>
        </>
    )
}

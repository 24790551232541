import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import MyBtnOrange from "../../../../ui/MyBtnOrange/MyBtnOrange";
import alfa from '../../../../assets/img/bankCards/alfa-card.svg'
import sber from '../../../../assets/img/bankCards/sber-card.svg'
import raiffeisen from '../../../../assets/img/bankCards/raiffeisen-card.svg'
import sbp from '../../../../assets/img/bankCards/sbp-card.svg'
import tinkoff from '../../../../assets/img/bankCards/tinkoff-card.svg'
import s from "../P2P/P2P.module.css"

export const ChooseBankSeparateLinkP2P = (props) => {
    const {firstNameP2P, lastNameP2P } = useSelector(store=>store.authP2P.data)
    const methods = useSelector(store => store.accountP2P.apiKey.methods)

    useEffect(() => {
        props.setSelectedCardId(null)
    }, [])

    const sendSelectedCard = (card) => {
        props.setSelectedCardId(card.id);
        props.setMethodId(card.methodId);
        props.next()
    }
    return (
        <>
            <div className={s.headerWrap}>
                <div className={s.chooseBank}>
                    <div className={s.icon}></div>
                    <div className={s.wrapHeader}>
                        <p className={s.title}>Выберите банк для оплаты</p>
                        <span className={s.name}>Выберите одну из доступных карт и нажмите на неё</span>
                    </div>
                </div>
                <div className={s.name}>Вы пополняете баланс аккаунта на {`${firstNameP2P} ${lastNameP2P}`}</div>
            </div>
            <ul className={s.cardsList}>
                {methods?.map((card) => (
                    <li key={card.id} className={s.bankCard}>
                        <div
                            onClick={() => props.setSelectedCardId(card.id)}
                            style={{
                                filter: card.available === false ? 'blur(4px)' : 'none',
                            }}
                        >
                            {card.name === 'Райффайзен' && <img src={raiffeisen} style={{width: "100%"}} alt={'raiffeisen'} />}
                            {card.name === 'Альфа-Банк' && <img src={alfa} style={{width: "100%"}} alt={'alfa'} />}
                            {card.name === 'СБП' && <img src={sbp} style={{width: "100%"}} alt={'sbp'} />}
                            {card.name === 'Сбербанк' && <img src={sber} style={{width: "100%"}} alt={'sber'} />}
                            {card.name === 'Тинькофф' && <img src={tinkoff} style={{width: "100%"}} alt={'tinkoff'} />}
                        </div>
                        <MyBtnOrange name='Выбрать' onClick={() => sendSelectedCard(card)}
                                     style={{
                                         filter: card.available === false ? 'blur(4px)' : 'none',                                         margin: '10px auto',
                                         width: '90%',
                                         color: '#DF853B',
                                         border: '1px solid #DF853B',
                                         background: 'transparent'
                                     }}
                        />
                    </li>
                ))}
            </ul>
        </>
    )
}
